import AppStore from "../../app-store";

const saveInitialTrafficSource = () => {
	if (!localStorage.getItem("traffic_source")) {
        let source = "direct"; // По умолчанию считаем переход прямым
        let medium = "none"; // Тип трафика по умолчанию
        let campaign = ""; // По умолчанию кампания отсутствует

        const referrer = document.referrer;
        const urlParams = new URLSearchParams(window.location.search);

        // Если присутствуют UTM-метки, получаем значения из них
        if (urlParams.has("utm_source")) {
            source = urlParams.get("utm_source");
            medium = urlParams.get("utm_medium") || medium;
            campaign = urlParams.get("utm_campaign") || campaign;
        } else if (referrer) {
            // Проверка реферера для определения источника
            if (referrer.includes("google.com")) {
                source = "google";
                medium = "organic";
            } else if (["facebook.com", "twitter.com", "instagram.com"].some(domain => referrer.includes(domain))) {
                source = "social";
                medium = referrer;
            } else {
                source = "referral";
                medium = referrer; // Записываем домен реферера как источник
            }
        }
    		AppStore.saveLocalStorageAndMobile({key: 'instock', value: JSON.stringify({ source, medium, campaign })})

        }
};

 const getTrafficSource = () => {
 	const trafficData = localStorage.getItem("traffic_source");
    if (trafficData) {
        return JSON.parse(trafficData);
    }

    // Если по каким-то причинам данных нет, возвращаем значения по умолчанию
    return {
        source: "direct",
        medium: "none",
        campaign: ""
    };

 };

export { saveInitialTrafficSource, getTrafficSource };