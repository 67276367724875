import React, { Component } from "react";
import { connectToStores } from "tools/reflux-tools";
import Actions from "modules/sales/sales-actions";
import SalesStore from "modules/sales/sales-store";
import ProductStore from "modules/products/products-store";
import ExpensesStore from "modules/expenses/expenses-store";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from "components/i18n";
import ReportsList from "components/reports-list";
import ProductsRow from "./products-row";
import ProductsTotal from "./products-total";
import ProductsFilter from "./products-filter";
import { filterSearch } from "tools/filters-helper";
import BaseFilterLayout from "../../../components/base-filter-layout";
import SalesActions from "modules/sales/sales-actions";
import { getDatePeriod } from "../../../tools/date-range";
import { getDay } from "../common/utils";
import CustomizeColumns from "../../../components/customize-columns";
import UserStore from "../../../user/user-store";
import ShipmentsStore from "../../shipments/shipments-store";
import UserActions from "../../../user/user-actions";
import calcMargin from "../../../tools/calc-margin";
import WarehouseStore from "../../warehouse/warehouse-store";
import {
  withIsMobileSize
} from "../../../components/is-mobile-context/is-mobile-context";
import ShipmentsActions from "../../shipments/shipments-actions";
import ProductsStore from "modules/products/products-store";
import Dropdown from "react-bulma-components/lib/components/dropdown";
import { LocalIcon } from "whitelables/wl-name/icons";
import DocumentModal from "../../../components/document-modal/document-modal";
import AppStore from "../../../app-store";
import numberFormat from "../../../tools/number-format";
import {formattedDate} from "../../../tools/formatted-date-dd-mm-yy";
import PaginationBlock from "../../../components/pagination";
import DynamicTabList
  from "../../../components/dynamic-tab-list/dynamic-tab-list";
import DocumentationButton
  from "whitelables/wl-name/common/documentation-button/documentation-button";

let COLUMNS = [
  "products-name",
  "total-sales-report",
  "dashboard-sales-profit",
  "average-sales",
  "sales-amounts",
  "product-info-warehouse",
  "days-groceries-left",
  // "average-inventory",
  "net-amounts",
  "profit-percentage",
  "margin",
];

const CUSTOMIZE_COLUMNS_DATA = COLUMNS.map((item, index) => ({
  label: item,
  is_global: item === "products-name" ? 1 : 0,
  position: index + 1,
  is_active: true,
}));

const KEY_MAP_SORT = {
  "product-info-warehouse": "stock",
  "days-groceries-left": "days-groceries-left",
  // "average-inventory": "average-inventory",
  "total-sales-report": "count",
  "average-sales": "average-sales",
  "net-amounts": "sum-net",
  "sales-amounts": "sum-revenue",
  "dashboard-sales-profit": "sum-gross-profit",
  "profit-percentage": "profit-percentage",
  margin: "margin",
  'products-name': 'products-name'

};

const DATA_SORT = COLUMNS.slice(1).map((item) => ({
  label: item,
  value: item,
}));

let SIZES = ["one-fifth", null, null, null, null, null, null];

class ProductsReport extends Component {
  constructor(props) {
    super(props);
    this.period = getDatePeriod(30);

    const getColumn = Object.values(
      UserStore.getCustomColumns("pr-report-column")
    );

    this.state = {
      sort: {},
      activeColumn: !!getColumn.length ? getColumn : CUSTOMIZE_COLUMNS_DATA,
      count_per_page: 50,
      count_page: 0,
      page: 0
    };

    this.onChange = this.onChange.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.soringFunc = this.soringFunc.bind(this);
    this.onSoring = this.onSoring.bind(this);
    this.refreshFilter = this.refreshFilter.bind(this);
    this.getPagination = this.getPagination.bind(this);
  }

  componentDidMount() {
    this.listenChangeColumn = UserActions.setCustomColumns.completed.listen(
      () => {
        const getColumn = Object.values(
          UserStore.getCustomColumns("pr-report-column")
        );
        this.setState({
          activeColumn: !!getColumn.length ? getColumn : CUSTOMIZE_COLUMNS_DATA,
        });
      }
    );
    this.listenDeleteColumn = UserActions.deleteCustomColumns.completed.listen(
      () => {
        this.setState({
          activeColumn: CUSTOMIZE_COLUMNS_DATA,
        });
      }
    );

    this.loadSales = SalesActions.load.completed.listen(() => {
      ShipmentsActions.filter('date_start', SalesStore.get('_filter').date_start)
    })

  }

  componentWillUnmount() {
    if (typeof this.listenChangeColumn == "function") {
      this.listenChangeColumn();
    }
    if (typeof this.listenDeleteColumn == "function") {
      this.listenDeleteColumn();
    }
    if (typeof this.loadSales == "function") {
      this.loadSales();
    }

    ProductsStore.clearFilter()
  }

  emptyMessage() {
    return (
      <Box align="center">
        <Heading>{T("not-have-history")}</Heading>
      </Box>
    );
  }

  getProcent(amount, profit) {
    return Math.round((profit / amount) * 100 * 100) / 100;
  }

  onChange({ name, value }) {
    SalesActions.filter(name, value);
  }

  onChangeDate(value) {
    const daysKey = Object.keys(value);
    daysKey.forEach((item) => {
      SalesActions.filter(item, value[item]);
    });
  }

  getQuantityProductWithoutDate(date, sale) {
    const end = new Date(date).setHours(23, 59, 59, 999);

    const saleDate = sale.purchase_date * 1000;
    if (saleDate >= end) {
      return sale.items.map((item) => ({
        pid: item.product_id,
        quantity: item.quantity,
      }));
    } else {
      return [];
    }
  }

  getProductTurnover({
    data,
    date_start,
    date_end,
    pidQuantitySalesWithoutDateStart,
    pidQuantitySalesWithoutDateEnd,
  }) {
    const period = getDay({ date_start, date_end });
    return data.map((item) => {
      const newItems = {};
      const quantityWithoutDateStart =
        pidQuantitySalesWithoutDateStart[item.pid] || 0;
      const quantityDateStart = quantityWithoutDateStart + item.stock;

      const quantityWithoutDateEnd =
        pidQuantitySalesWithoutDateEnd[item.pid] || 0;
      const quantityDateEnd = quantityWithoutDateEnd + item.stock;

      newItems["average-sales"] = (item.count / period).toFixed(2);
      newItems["days-groceries-left"] = Math.round(
        item.stock / parseFloat(newItems["average-sales"])
      );
      if(isNaN(newItems["days-groceries-left"])) {
        newItems["days-groceries-left"] = 0
      } else if(!Number.isFinite(newItems["days-groceries-left"])) {
        newItems["days-groceries-left"] = '∞'
      }
      newItems["average-inventory"] = Math.floor(
        (quantityDateStart + quantityDateEnd) / 2
      );
      newItems["quantityDateStart"] = quantityDateStart;
      newItems["quantityDateEnd"] = quantityDateEnd;

      return {
        ...item,
        ...newItems,
      };
    });
  }

  generateData() {
    const data = SalesStore.getSales(),
      search = SalesStore.getFilter("search"),
      filter_mid =
        SalesStore.getFilter("mid").value > 0
          ? SalesStore.getFilter("mid").value
          : false,
      filter_category =
        SalesStore.getFilter("category").value > 0
          ? SalesStore.getFilter("category").value
          : false,
      filter_brand =
        SalesStore.getFilter("brand").value > 0
          ? SalesStore.getFilter("brand").value
          : false,
      date_start = SalesStore.getFilter("date_start"),
      date_end = SalesStore.getFilter("date_end"),
      filter_supplier =
        ShipmentsStore.getFilter("supplier").value > 0
          ? ShipmentsStore.getFilter("supplier").value
          : false,
      filter_channel =
        SalesStore.getFilter('channel').value > 0
          ? SalesStore.getFilter('channel').value
          : false

    const filterAttr = Object.values(ProductsStore.getFilter("attr")).some(item => !!item.value)

    let dataSupplier = ShipmentsStore.getList();

    if (filter_supplier) {
      dataSupplier = dataSupplier.filter(
        (item) =>
          filter_supplier === item.supplier_id &&
          item.shipment_status === "complete"
      );
    }

    let response = {},
      totals = {
        count: 0,
        "sum-revenue": 0,
        margin: 0,
        "sum-net": 0,
        "sum-gross-profit": 0,
      };

    let expenses_profit = 0;
    // for (let i in expenses) {
    //   let cat_id = expenses[i][0], amount = expenses[i][1];

    //   if (ExpensesStore.ifProfitInfluenceCategory(cat_id)) {
    //     expenses_profit += amount;
    //   }
    // }

    totals["expenses_profit"] = expenses_profit;

    const pidQuantitySalesWithoutDateStart = {};
    const pidQuantitySalesWithoutDateEnd = {};

    for (let i in data) {
      let d = data[i];

      if (
        ExpensesStore.isNotAllowToCalc(d["account_id"]) ||
        d["order_status"] === "reserve_sale" || ExpensesStore.isDebt(d["account_id"])
      ) {
        continue;
      }


      if(filter_channel) {
        const channelIds = d.channel_id;

        const isChannel = channelIds?.some(item => item === filter_channel)
        if(!isChannel) {
          continue;
        }
      }

      for (let j in d["items"]) {
        const item = d["items"][j],
          pid = item["product_id"],
          product = ProductStore.getProduct(pid)

          const name = product.name
            ? product.name
            : (item.product_deleted === 1 || !product) && (
                <span className="has-text-danger"> {T("product_deleted")}</span>
              );

        if (filter_mid && parseInt(item["mid"]) !== parseInt(filter_mid)) {
          continue;
        }

        if (filter_category) {
          let childs_category =
            ProductStore.get("categoryChild")[filter_category];
          if (typeof childs_category === "undefined") {
            childs_category = [filter_category];
          }

          if (childs_category.indexOf(product["category_id"]) === -1) {
            continue;
          }
        }

        if (filter_brand) {
          if (product["brand_id"] !== filter_brand) {
            continue;
          }
        }

        if(filterAttr) {
          const result = ProductsStore.filterVariableAttr(product['attr_desc']);
          if(!result) {
            continue;
          }
        }

        if (filter_supplier) {
          const result = dataSupplier.some((obj) => {
            return obj.items.some((item) => item.product_id === pid);
          });

          if (!result) continue;
        }
        let search_data = {
          pid,
          barcode: product["asin"],
          sku: product["skus"],
          product_name: name,
          order_number: d["order_number"] || d["id"],
        };

        let result = filterSearch(
          search,
          ["barcode", "product_name", "sku", "order_number",'pid'],
          search_data
        );
        if (!result) {
          continue;
        }

        let stock = 0;

        let isDrop = item.product_deleted !== 1

        for (let k in product["stock"]) {
          let st = product["stock"][k];
          if (filter_mid && st["mid"] !== filter_mid) {
            continue;
          }
          const isDropWarehouse =  WarehouseStore.isDropshipping(k)
          if(isDrop && !isDropWarehouse) {
            isDrop = false;
          }
          stock += st["instock"];
        }

        const productPidAndQuantityIsDateStart =
          this.getQuantityProductWithoutDate(date_start, d);

        productPidAndQuantityIsDateStart.forEach((item) => {
          if (pidQuantitySalesWithoutDateStart[item.pid]) {
            pidQuantitySalesWithoutDateStart[item.pid] += item.quantity;
          } else {
            pidQuantitySalesWithoutDateStart[item.pid] = item.quantity;
          }
        });

        const productPidAndQuantityIsDateEnd =
          this.getQuantityProductWithoutDate(date_end, d);

        productPidAndQuantityIsDateEnd.forEach((item) => {
          if (productPidAndQuantityIsDateEnd[item.pid]) {
            pidQuantitySalesWithoutDateEnd[item.pid] += item.quantity;
          } else {
            pidQuantitySalesWithoutDateEnd[item.pid] = item.quantity;
          }
        });

        if (typeof response[pid] === "undefined") {
          response[pid] = {
            pid: pid,
            "products-name": name,
            units: product["units"],
            stock: stock,
            "sum-revenue": 0,
            "sum-net": 0,
            "sum-gross-profit": 0,
            "profit-percentage": 0,
            count: 0,
            margin: 0,
            brand_name: product["brand"],
            category_name: ProductStore.getCategoryName(product.category_id),
            isDrop,
          };
        }

        response[pid]["sum-revenue"] += item["amount"];
        response[pid]["sum-net"] += item["net_price"];
        response[pid]["sum-gross-profit"] += item["profit"];
        response[pid]["count"] += item["quantity"];

        totals["count"] += item["quantity"];
        totals["sum-revenue"] += item["amount"];
        totals["sum-net"] += item["net_price"];
        totals["sum-gross-profit"] += item["profit"];
      }
    }

    totals["margin"] = calcMargin(totals["sum-net"], totals["sum-revenue"])

    let for_sorting = [];
    for (let pid in response) {
      response[pid]["margin"] = calcMargin(response[pid]["sum-net"], response[pid]["sum-revenue"])

      response[pid]["profit-percentage"] = this.getProcent(
        totals["sum-gross-profit"],
        response[pid]["sum-gross-profit"]
      );

      for_sorting.push(response[pid]);
    }

    totals.count = totals.count % 1 === 0 ? totals.count : totals.count.toFixed(2)

    for_sorting = this.getProductTurnover({
      data: for_sorting,
      date_start,
      date_end,
      pidQuantitySalesWithoutDateStart,
      pidQuantitySalesWithoutDateEnd,
    });
    for_sorting = this.soringFunc(for_sorting);

    if(this.state.count_page !== Math.ceil(for_sorting.length/this.state.count_per_page)) {
      this.setState({count_page: Math.ceil(for_sorting.length/this.state.count_per_page), page: 0})
    }

    return [for_sorting, totals];
  }

  soringFunc(data) {
    const soringName = this.state.sort;
    const name = Object.keys(soringName)[0];
    const value = soringName[name];

    const realSortKey = KEY_MAP_SORT[name];
    const compareFunction = (a, b) => {
      let itemA = a[realSortKey];
      let itemB = b[realSortKey];

      if(React.isValidElement(itemA)) {
        itemA = itemA.props.children[1];
      }
      if(React.isValidElement(itemB)) {
        itemB = itemB.props.children[1];
      }

      if (value === 2) {
        return 0;
      }

      if (typeof itemA === 'string' && typeof itemB === 'string') {
        return value === 0
          ? itemB.localeCompare(itemA)
          : itemA.localeCompare(itemB);
      }

      return value === 0
        ? itemA - itemB
        : itemB - itemA;
    };
    data.sort(compareFunction);
    return data;
  }

  onSoring(value) {
    this.setState({
      sort: value,
    });
  }

  onResetColumns() {
    UserActions.deleteCustomColumns("pr-report-column");
  }

  refreshFilter() {
    SalesStore.clearFilter();
    SalesActions.filter("date_start", this.period);
    SalesActions.load();
  }

  additionActions = (reports, ACTIVE_COLUMNS) => {

    const COLUMNS = [...ACTIVE_COLUMNS]

    COLUMNS.splice(1, 0, 'category', 'brand')

    const generateList = reports.map(data => {
      const KEY_MAP = {
        "products-name": `ID: ${data.pid}. ${React.isValidElement(data['products-name']) ? data['products-name'].props.children[1] : data['products-name']}`,
        "product-info-warehouse": () => {
          const isServices = ProductsStore.isServicesProduct(data.pid)
          const isDrop = data.isDrop
          return  isServices || isDrop ? "∞" : ProductsStore.getStockUnits(data.stock, data.units)
        },
        "days-groceries-left": data["days-groceries-left"],
        "total-sales-report": ProductsStore.getStockUnits(data["count"], data["units"]),
        "average-sales": ProductsStore.getStockUnits(data["average-sales"], data["units"]),
        "net-amounts": `${numberFormat(data["sum-net"])} ${UserStore.getCurrency()}`,
        "sales-amounts": `${numberFormat(data["sum-revenue"])} ${UserStore.getCurrency()}`,
        "dashboard-sales-profit": `${numberFormat(data["sum-gross-profit"])} ${UserStore.getCurrency()}`,
        "profit-percentage": `${data["profit-percentage"]}%`,
        margin: `${data["margin"]}%`,
        category: data['category_name'] || '-',
        brand: data['brand_name'] || '-'
      }

      return COLUMNS.map((item) => {
        if(typeof KEY_MAP[item] === 'function') {
          return KEY_MAP[item]()
        } else {
          return KEY_MAP[item]
        }
      })
    })

    const heading = `${T('product-report')} ${formattedDate(SalesStore.getFilter("date_start"))} - ${formattedDate(SalesStore.getFilter("date_end"))}`

    const list = {
        "head": {
          "title": heading,
          "table_cols": COLUMNS.map(item => T(item)),
          "table_widths_cols": COLUMNS.map((item, index) => index === 0 ? 3 : 1)
        },
        "table": generateList,
      }


    if(ACTIVE_COLUMNS.length > 6) {
      list.head.orientation = 1
    }

    return (
      <Dropdown right={false} label={<LocalIcon icon="download" size="large" />}>
        <Dropdown.Item
          target="_blank"
          title={T("export-pdf")}
          value="item"
        >
          <div onClick={() => AppStore.openModal(<DocumentModal printDocument={list} format='pdf' heading={heading}/>)}>
            {T("export-pdf")}
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          title={T("export-excel")}
          value="item"
        >
          <div onClick={() => AppStore.openModal(<DocumentModal printDocument={list} format='xls' heading={heading}/>)}>
            {T("export-excel")}
          </div>
        </Dropdown.Item>
      </Dropdown>
    );
  };

  renderFilter(reports, ACTIVE_COLUMNS) {
    const view = ["brand", "category", "mid", "clients", 'channel'];

    return (
      <BaseFilterLayout
        searchStringSetting={{
          onChange: this.onChange,
          defaultValue: SalesStore.getFilter("search"),
        }}
        dateRangeSetting={{
          onChange: this.onChangeDate,
          date_start: SalesStore.getFilter("date_start"),
          date_end: SalesStore.getFilter("date_end"),
        }}
        refresh={{
          onChange: this.refreshFilter,
          isLoading: SalesStore.get("isLoading"),
        }}
        sortSetting={{
          data: DATA_SORT,
          defaultActive: this.state.sort,
          onChange: (value) => this.onSoring(value),
        }}
        filterView={{
          list: SalesStore.get("_filter"),
          view,
          onDelete: SalesStore.filterClearField,
        }}
        hiddenFilter={<ProductsFilter />}
        additionalSetting={this.additionActions(reports, ACTIVE_COLUMNS)}
      />
    );
  }

  getPagination(list) {
    const handleChangePerPage = (value) => {
      this.setState({
        count_per_page: value,
        page: 0,
        count_page: Math.ceil(list.length/value),
      })
    }

    return <PaginationBlock
      setPage={(page)=>{this.setState({page: parseInt(page)})}}
      totalPage={this.state.count_page}
      currentPage={this.state.page}
      countPerPage={this.state.count_per_page}
      onChangePerPage={handleChangePerPage}
    />
  }

  render() {
    const emptyMessage = this.emptyMessage,
      data = this.generateData(),
      reports = data[0],
      totals = data[1];

    const notIncomeInformation = ["dashboard-sales-profit","net-amounts", "sales-amounts", "profit-percentage", "margin"]

    const ACTIVE_COLUMNS = this.state.activeColumn
      .sort((a, b) => a.position - b.position)
      .reduce((acc, cur) => {
        if (cur.is_active) {
          if(UserStore.getPermissionEnable('incomeinformation')) {
            acc.push(cur.label);
          } else if(!notIncomeInformation.includes(cur.label)) {
            acc.push(cur.label);
          }
        }

        return acc;
      }, []);


    const start = this.state.page * this.state.count_per_page
    const end = start + this.state.count_per_page;


    return (
      <div className="products-report">
        {!this.props.isMobileSize && this.renderFilter(reports, ACTIVE_COLUMNS)}
        <ProductsTotal rows={totals} />
        {this.props.isMobileSize && <div align='left' className='margin-bottom-10'><DocumentationButton sectionID='report-products'/></div>}
        {!this.props.isMobileSize && (
          <div className='display-flex-row-gap margin-bottom-10 flex-wrap'>
            <DocumentationButton sectionID='report-products'/>
            <CustomizeColumns
              customizeColumns={CUSTOMIZE_COLUMNS_DATA.filter(item => {
                if(UserStore.getPermissionEnable('incomeinformation')) {
                  return true
                } else if(!notIncomeInformation.includes(item.label)) {
                  return true
                }
                return false
              })}
              customizeColumnsActive={UserStore.getCustomColumns(
                "pr-report-column"
              )}
              columnsName="pr-report-column"
              onReset={this.onResetColumns}
            />
          </div>
        )}
        {this.props.isMobileSize && <DynamicTabList
          list={this.props.listTabs}
          onChangeTab={this.props.onChangeTab}
          defaultTab={this.props.defaultTab}
        />}
        <Box className="report-list border-not-top" style={this.props.isMobileSize ? {overflow: 'visible'} : { overflow: "auto" }}>
          {this.props.isMobileSize && this.renderFilter(reports, ACTIVE_COLUMNS)}
          {this.getPagination(reports)}
          <ReportsList
            {...this.props}
            minWidthList={ACTIVE_COLUMNS.length * 150 + 200}
            load={Actions.load}
            store={SalesStore}
            emptyMessage={emptyMessage}
            columns={this.props.isMobileSize ? [] : ACTIVE_COLUMNS}
            sizes={SIZES}
            hideFooterOnEmpty
            isLoading={SalesStore.get("isLoading")}
            isSortingColumns={[
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
            ]}
            sortActions={this.onSoring}
            isSortNotRefValue
            stickyFirstColumnHeader
            rows={reports.slice(start, end)}
          >
            <ProductsRow role="row" activeColumn={this.state.activeColumn} />
          </ReportsList>
          {this.getPagination(reports)}
        </Box>
      </div>
    );
  }
}

export default connectToStores(withIsMobileSize(ProductsReport), {
  _: SalesStore,
  ship: ShipmentsStore,
});
