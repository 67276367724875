import React, { Component } from 'react';
import T from "components/i18n";
import Money from "components/money";
import { redirectTo } from 'tools/redirect-to'
import ScrollToTopOnMount from 'tools/scroll-top'
import {
    Field, 
    Control, 
    Input, 
    Label, 
} from 'react-bulma-components/lib/components/form';
import Notification from 'react-bulma-components/lib/components/notification';
import Columns from 'react-bulma-components/lib/components/columns';
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import SalesActions from 'modules/sales/sales-actions'
import ExpensesActions from 'modules/expenses/expenses-actions'
import ExpensesStore from 'modules/expenses/expenses-store'
import SelectComponent from 'tools/select-component';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";
import AppStore from "../../app-store";
import EditSalesModal from "./edit-sales-modal"; // Import css


class FinishReservModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          oid: props.oid,
          number: props.number,
          amount: props.total_order - props.prepaid,
          saleAccount: props.aid,
          fromAccount: {'value': props.aid},
          prepaid: props.prepaid,
          close: false,
          message: null,
          step: 0
        };
        this.remove = false;

        this.onChange = this.onChange.bind(this);
        this.onChangeAccount = this.onChangeAccount.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.submit = this.submit.bind(this);
        this.renderStepOne = this.renderStepOne.bind(this);
        this.renderStepSecond = this.renderStepSecond.bind(this);

    }

    componentWillUnmount() {
      if (typeof(this.listenAdd) == "function") {
          this.listenAdd();
      }

      if (typeof(this.listenAddFailed) == "function") {
        this.listenAddFailed();
      }

      if (typeof(this.listenDelete) == "function") {
          this.listenDelete();
      }

      if (typeof(this.listenDeleteFailed) == "function") {
        this.listenDeleteFailed();
      }      

      if (typeof(this.listenCancelReserv) == "function") {
          this.listenCancelReserv();
      }

      if (typeof(this.listenCancelReservFailed) == "function") {
        this.listenCancelReservFailed();
      }
    }

    componentDidMount() {
        this.listenFinish = SalesActions.finishReserv.completed.listen((data) => {
          if (data['success']) {
            this.setState({step: 1, 'finish_message': 'reserv-success'});
          }
        });

        this.listenFinishFailed = SalesActions.finishReserv.failed.listen((res) => {
            const data = JSON.parse(res['response']);
            
            this.setState({
              "message": T(data['error']),
            });
        });

        this.listenDelete = SalesActions.deleteSale.completed.listen((data) => {
          if (data['success']) {
            this.setState({close: true});
          }
        });

        this.listenDeleteFailed = SalesActions.deleteSale.failed.listen((res) => {
            const data = JSON.parse(res['response']);
            
            this.setState({
              "message": T(data['error']),
            });
        });

        this.listenCancelReserv = SalesActions.cancelReserv.completed.listen((data) => {
          if (data['success']) {
            const mes = this.remove ? 'reserv-success-delete' : 'cancel-reserv-success';

            this.setState({step: 1, 'finish_message': mes});
          }
        });

        this.listenCancelReservFailed = SalesActions.cancelReserv.failed.listen((res) => {
            const data = JSON.parse(res['response']);
            
            this.setState({
              "message": T(data['error']),
            });
        });
    }

    getMessage() {
        setTimeout(() => {
          this.setState({
            "message": null
          })
        }, 4000);
        return (
              <Notification color="danger">
                    <ScrollToTopOnMount />
                    {this.state.message}
                    <Button remove onClick={() => this.setState({message: null})} />
              </Notification>
        )
    }

    onChange(evt) {
        const value = evt.target.value,
            name = evt.target.name;

        this.setState({
          [name]: value
        });
    }

    onChangeCheckbox(evt) { 
        const value = evt.target.checked,
              name = evt.target.name

         this.setState({
          [name]: value
         })
    }


    onChangeAccount(selectedOption, actions) {
      if (!selectedOption) return;

      console.log("actions: ", selectedOption, actions)
     
      this.setState({
        [actions.name]: selectedOption
      });

      setTimeout(this.calcCommission, 10);
    }

    deleteSale(oid) {
      this.remove = true;
        confirmAlert({
          title: T('confirm-delete'),
          message: T('are-you-sure-to-delete-this-reserv'),
          buttons: [
            {
              label: T('delete'),
              onClick: () => SalesActions.cancelReserv({
                "oid": this.state.oid,
                "full": true,
              })
            },
            {
              label: T('no-delete'),
              onClick: () => {
                this.remove = false;
              }
            }
          ]
        });
    }


    submit(type) {
      switch (type) {
            case 'remove':
              this.deleteSale(this.state.oid);
              break;
            case 'finish':
              SalesActions.finishReserv({
                "oid": this.state.oid, 
                "aid": this.state.fromAccount.value, 
                "amount": this.state.amount,
              });
              break;
            case 'cancel':
              this.cancelReserv();
              break;
            case 'reserve_sale':
            default:
              break;
      }

        
    }

    cancelReserv() {
        confirmAlert({
          title: T('remove-reserv'),
          message: T('are-you-sure-remove-reserv'),
          buttons: [
            {
              label: T('remove-reserv'),
              onClick: () => SalesActions.cancelReserv({
                "oid": this.state.oid
              })
            },
            {
              label: T('back'),
              onClick: () => {}
            }
          ]
        });
    }

    renderStepOne() {
      return (
          <Box>
              <Heading>
                  {T('finish-reserv-title')}
              </Heading>

              <Columns className="info-block">
                  <Columns.Column><span className="silver">{T('order')}</span> <br/>{this.state.number}</Columns.Column>
                  <Columns.Column><span className="silver">{T('prepaid')}</span> <br/><Money aid={this.state.saleAccount} amount={this.state.prepaid} /></Columns.Column>
                  <Columns.Column><span className="silver">{T('to-account')}</span> <br/>{T(ExpensesStore.getAccountName(this.state.saleAccount))}</Columns.Column>
              </Columns>

              <Field>
                  <Control>
                    <Label>{T('need-balance')}</Label>
                    <Input  size="large" 
                          name="amount"
                          type="number"
                          onChange={this.onChange} 
                          value={this.state.amount}
                          />
                  </Control>
              </Field>
              <Field>
                  <Control>
                    <SelectComponent 
                        name="fromAccount"
                        label="to-account"
                        onChange={this.onChangeAccount} 
                        value={this.state.fromAccount} 
                        list={ExpensesStore.getPersonalAccounts}
                        load={ExpensesActions.loadAccounts}
                        creatable={false}/>
                </Control>
              </Field> 

              <div align="center" className="margin-bottom-10">
              <Button  size={this.props.isMobileSize ? "small" : "large"} fullwidth rounded color="primary"
                      onClick={() => this.submit('finish')}>{T('finish-sale-btn')}</Button>
              </div>
              <Button.Group position="centered">
                    <Button onClick={() => {
                      AppStore.openModal(<EditSalesModal oid={this.state.oid} resetDate type_sale='delivery'/>)
                      this.props.onClose()
                    }}  size={this.props.isMobileSize ? "small" : "medium"} rounded color="info">{T('send-parcel')}</Button>
                    <Button  size={this.props.isMobileSize ? "small" : "medium"} rounded
                      onClick={() => this.submit('cancel')}>{T('remove-reserv')}</Button>
               <Button renderAs="a" color="danger" size={this.props.isMobileSize ? "small" : "medium"} rounded onClick={() => this.submit('remove')}>{T('delete')}</Button>
               <Button color="light" size={this.props.isMobileSize ? "small" : "medium"} rounded onClick={this.props.onClose}>{T('close')}</Button>
              </Button.Group>
          </Box>
      );
    }

    renderStepSecond() {
      const {onClose} = this.props;
      return (
        <Box align="center">
          <div className="modal-icon">
                  <img src="/img/icons/ok.png" alt="print-icon" width="88px" border="0"/>
          </div>
          <Heading className="title">{T(this.state.finish_message)}</Heading>
          <Button.Group>
              <Button fullwidth size="large"  rounded color="primary"
                onClick={() => {onClose(); this.setState({close: true})}}
                >{T('continue-btn')}</Button>
            </Button.Group>
        </Box>
      );
    }

    render() {
        const stepNaviganor = [this.renderStepOne, this.renderStepSecond],
              currentStep = stepNaviganor[this.state.step];


        return (
              <div>
                {this.state.message ? this.getMessage() : ""}
                {currentStep()}
                {this.state.close ? redirectTo("/sales") : ""}
              </div>
        );

    }
       
}

export default withIsMobileSize(FinishReservModal);
