import React, {useEffect, useRef, useState} from 'react'
import T from "../../../../components/i18n";
import SalesStore from "../../../sales/sales-store";
import s from './styles.module.scss'
import DatePicker from "react-datepicker/es";
import {Box, Button} from "react-bulma-components";
import {Label} from "react-bulma-components/lib/components/form";
import User from "../../../../user/user-store";
import SubmitButton from "../../../../components/submit-button";
import {connectToStores} from "../../../../tools/reflux-tools";
import SalesActions from "../../../sales/sales-actions";

const DateReportChange = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [date, setDate] = useState(SalesStore.getStartFrom(true))
	const [waiting, setWaiting] = useState(false)
	const wrapperRef = useRef(null);

	const handleClose = () => {
		setIsOpen(false)
	}

	const handleChangeDate = (value) => {
		setDate(value)
	}

	const handleSubmit = () => {
		SalesActions.filter('dateStartReport', date)
		SalesActions.loadReports()
		setWaiting(true)

		setTimeout(() => {handleClose(); setWaiting(false)}, 3000)
	}

	useEffect(() => {
		const loadSales = SalesActions.loadReports.completed.listen(() => {
			setWaiting(false)
			handleClose()
		})

		return () => {
			loadSales()
		}
	}, [])

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				handleClose();
			}
		};

		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	const now = new Date();
	const ninetyDaysAgo = new Date(now.getTime() - 63 * 24 * 60 * 60 * 1000);

	return (
		<div className={s.wrapper}>
			{isOpen && <div className={s.date_wrapper} ref={wrapperRef}>
				<Box>
					<Label align='left'>{T('display-sales-from')}</Label>
					<div>
						<DatePicker
							className="input"
							selected={date}
							onChange={handleChangeDate}
							name="start_date"
							dateFormat="dd-MM-yyyy"
							locale={User.getLang()}
							maxDate={ninetyDaysAgo}
						/>
						<div className='margin-top-10 display-flex-row' style={{justifyContent: 'flex-end'}}>
							<Button size='small' rounded onClick={handleClose} disabled={waiting}>
								{T('close')}
							</Button>
							<SubmitButton text='confirm-label' size='small' color='success'  fullwidth={false} submit={handleSubmit} waiting={waiting}/>
						</div>
					</div>
				</Box>
			</div>}
			<Button className='button-link-without-style' style={{fontSize:'0.75rem'}} onClick={() => setIsOpen(prev => !prev)}>
						 {T('with')} {SalesStore.getStartFrom()}
			</Button>
		</div>
	);
};

export default connectToStores(DateReportChange, {SalesStore});