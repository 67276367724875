import React from "react";
import BaseFilterLayout from "../../../components/base-filter-layout";
import SelectStore from "../select-store";
import SalesStore from "../sales-store";
import SalesFilter from "../sales-filter";
import Actions from "../sales-actions";
import Dropdown from "react-bulma-components/lib/components/dropdown";
import {Link, useLocation} from "react-router-dom";
import T from "../../../components/i18n";
import { LocalIcon } from "whitelables/wl-name/icons";
import SalesActions from "../sales-actions";

const AdditionActions = () => {
  return (
    <Dropdown right={false} label={<LocalIcon icon="download" size="large" />}>
      <Dropdown.Item
        renderAs={Link}
        to={SalesStore.getExport("pdf")}
        target="_blank"
        title={T("list-print-slip")}
        value="item"
      >
        {T("list-print-slip")}
      </Dropdown.Item>
      <Dropdown.Item
        renderAs={Link}
        to={SalesStore.getExport("xls")}
        target="_blank"
        title={T("list-print-slip-xls")}
        value="item"
      >
        {T("list-print-slip-xls")}
      </Dropdown.Item>
    </Dropdown>
  );
};

const FilterPanel = ({ onChangeSelectAllCheckbox, isRefund }) => {
  const location = useLocation()

  const pathname = location.pathname;
  const splitPathname = pathname.split("/");

  let defaultTab = splitPathname[2];
  if (defaultTab === "reserv") {
    defaultTab = "reserve_sale";
  } else if (defaultTab === "refund-list") {
    defaultTab = "refund";
  }

  const refreshFilter = () => {
    if(isRefund) {
      Actions.loadRefund()
    } else {
      Actions.load(true, defaultTab === "saled");
    }
    SalesStore.clearFilter(true, true);
  };

  const handleChange = ({ name, value }) => {
    if(name) {
      SalesActions.filter('search', value, defaultTab === "saled");
    }
  };

  const handleChangeDate = (value) => {
    const daysKey = Object.keys(value);
    daysKey.forEach((item) => {
      if(value[item]) {
        if(isRefund) {
          SalesActions.filterRefund(item, value[item], defaultTab === "saled");
        } else {
          SalesActions.filter(item, value[item], defaultTab === "saled");
        }
      }
    });
  };

  const view = ['account', 'channel', "clients", "mid", "rid", "category", "delivery", "deliveryCity", 'deliveredKeeperDate', 'commission'];

  return (
    <BaseFilterLayout
      searchStringSetting={{
        onChange: handleChange,
        defaultValue: location.state?.search || SalesStore.getFilter("search"),
      }}
      selectAllSetting={{
        onChange: onChangeSelectAllCheckbox,
        isChecked: SelectStore.getIsSelectAll(SalesStore.getSales(), "oid"),
      }}
      refresh={{
        onChange: refreshFilter,
        isLoading: SalesStore.get("isLoading"),
      }}
      dateRangeSetting={{
        onChange: handleChangeDate,
        date_start: location.state?.period || SalesStore.getFilter("date_start"),
        date_end: SalesStore.getFilter("date_end"),
      }}
      additionalSetting={<AdditionActions />}
      hiddenFilter={<SalesFilter />}
      filterView={{
        list: SalesStore.get("_filter"),
        view,
        onDelete: SalesStore.filterClearField,
      }}
    />
  );
};

export default FilterPanel;
