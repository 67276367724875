import yajax from "yajax";
import { createStore } from "../../tools/reflux-tools";
import Actions from "./expenses-actions";
import TransactionsActions from "modules/transactions/transactions-actions";
import T from "components/i18n";
import User from "user/user-store";
import { filterSearch } from "tools/filters-helper";
import UserStore from "user/user-store";

const TYPES_DEBT = 4,
  TYPES_COD = 1;

const CLEAR_FILTER = () => ({
  status: "all",
  date_start: new Date(),
  date_end: new Date(),
  account: { value: 0 },
  category: { value: 0 },
  search: "",
  sortCategory: {},
});

const ExpensesStore = createStore({
  listenables: Actions,
  _prefix: "/bexpenses/",
  SHIPMENT_CATEGORY: 11,
  state: {
    rows: null,
    report: null,
    expenses_category: null,
    receipt_category: null,
    accounts: null,
    isLoaded: false,
    isStartLoaded: false,
    _filter: CLEAR_FILTER(),
  },

  typeAccounts: {
    0: "cash",
    1: "cod",
    2: "credit-card",
    3: "payment-account",
    4: "debt",
  },

  actions: {
    1: 1,
    2: -1,
    3: 1,
    4: -1,
    5: -1,
    6: -1,
    7: 1,
    8: -1,
    9: -1,
    10: 1,
    11: 1,
  },

  get(field) {
    return this.state[field];
  },

  getFilter(field) {
    return this.state["_filter"][field];
  },

  clearFilter() {
    this.setState({
      _filter: CLEAR_FILTER(),
    });
  },

  onFilter(name, value) {
    let filters = {};

    for (let i in this.state["_filter"]) {
      filters[i] = this.state["_filter"][i];
    }

    filters[name] = value;

    this.setState({
      _filter: filters,
    });
  },

  applyFilter(expenses) {
    const filter = this.state._filter,
      date = new Date(expenses["created_at"] * 1000);
    let result = true;

    if(filter['date_start'] && filter['date_end']) {
      filter["date_start"].setHours(0, 0, 0, 0);
      filter["date_end"].setHours(23, 59, 59, 999);
    } else {
      return false
    }

    if (date < filter["date_start"] || date > filter["date_end"]) {
      return false;
    }

    if (filter["search"] !== "" && typeof filter["search"] !== "undefined") {
      let search_data = {
        name: expenses["name"],
      };

      result = filterSearch(filter["search"], ["name"], search_data);
    }

    if (filter["account"] && filter["account"]["value"] !== 0) {
      if (
        parseInt(filter["account"]["value"]) !==
        parseInt(expenses["account_id"])
      ) {
        result = result && false;
      }
    }

    if (filter["category"] && filter["category"]["value"] !== 0) {
      if (
        parseInt(filter["category"]["value"]) !==
        parseInt(expenses["category_id"])
      ) {
        result = result && false;
      }
    }

    return result;
  },

  getTypeActionsList() {
    let list = [];

    for (let i in this.typeAccounts) {
      list.push({
        name: T(this.typeAccounts[i]),
        id: i,
      });
    }

    return list;
  },

  getActionsList() {
    let list = [];

    for (let i in this.actions) {
      list.push({
        name: T("action-" + i),
        id: i,
      });
    }

    return list;
  },

  checkOnBoarding() {
    if (User.getOnBoaringStep() === 4 && this.get("rows")) {
      if (this.get("rows").length > 0) {
        User.setOnBoaringStep(5);
      }
    }
  },

  getAccounts(withDeleted) {
    const accounts = this.get("accounts");

    if (accounts === null) {
      Actions.loadAccounts();
      return [];
    }

    let list = {};
    for (let i in accounts) {
      let acc = accounts[i];
      if (parseInt(acc["is_deleted"]) !== 1 || withDeleted) {
        list[acc["id"]] = acc;
      }
    }

    return list;
  },

  hasManyCurrencies() {
    const accounts = this.getAccounts();

    let curr = {};
    for (let i in accounts) {
      let acc = accounts[i];

      curr[acc["currency_id"]] = 1;
    }

    return Object.keys(curr).length > 1;
  },

  getAccountsCurrency() {
    const accounts = this.getAccounts(),
      currencies = User.get("currencies");

    let data = {};
    for (let j in currencies) {
      let curr = currencies[j];
      for (let i in accounts) {
        let acc = accounts[i];
        if (acc["currency_id"] === curr["id"]) {
          data[curr["id"]] = curr;
        }
      }
    }

    return data;
  },

  getAccountsCurrencyList() {
    const data = this.getAccountsCurrency();

    let options = [];
    for (let i in data) {
      options.push({ id: data[i]["id"], name: data[i]["name"] });
    }

    return options;
  },

  getSortedAccounts(withDeleted) {
    const accounts = this.getAccounts(withDeleted);

    if (accounts === null) {
      Actions.loadAccounts();
      return [];
    }

    const sortedAccounts = Object.entries(accounts).sort((a, b) => {
      const positionA = a[1].position || 99; // Если position не указан, считаем его как 0
      const positionB = b[1].position || 99;
      return positionA - positionB;
    });

    return sortedAccounts;
  },

  getAccountsList(types, curr, not_types, withDeleted) {
    let list = [];

    const accounts = this.getSortedAccounts(withDeleted);
    for (let [key, acc] of accounts) {
      let conditions = true;
      if (key) {
      }
      if (typeof types !== "undefined" || typeof not_types !== "undefined") {
        if (typeof types !== "object") {
          types = [types];
        }

        if (not_types) {
          conditions = types.indexOf(parseInt(acc["types"])) === -1;
        } else {
          conditions = types.indexOf(parseInt(acc["types"])) > -1;
        }
      }

      if (conditions) {
        let balance =
          " (" +
          acc["balance"] +
          " " +
          this.getAccountCurrency(acc["id"]) +
          ")";

        if (!User.getPermissionEnable("showbalance")) {
          balance = "";
        }

        if (
          typeof curr === "undefined" ||
          !curr ||
          parseInt(curr) === parseInt(acc["currency_id"])
        ) {
          list.push({
            name: T(acc["name"]) + balance,
            id: acc["id"],
          });
        }
      }
    }

    return list;
  },

  getAccountWithoutTypesForSelect(types, currency, withDeleted) {
    const accounts = this.getSortedAccounts(withDeleted);
    const dataAccounts = accounts.map((item) => item[1]);

    let filterArray = dataAccounts.filter(
      (account) => !types.includes(account.types)
    );

    if (currency) {
      filterArray = filterArray.filter((item) => item.currency_id === currency);
    }
    return filterArray.map((account) => {
      let name = T(account["name"]);

      if (User.getPermissionEnable("showbalance")) {
        name = `${name} (${account["balance"]} ${this.getAccountCurrency(
          account["id"]
        )})`;
      }

      return {
        id: account["id"],
        name,
      };
    });
  },

  getAccountNameForSelectById(id, withDeleted) {
    const list = this.getAccounts(withDeleted);

    if (list[id]) {
      const acc = list[id];
      let balance =
        " (" + acc["balance"] + " " + this.getAccountCurrency(acc["id"]) + ")";

      if (!User.getPermissionEnable("showbalance")) {
        balance = "";
      }

      return T(acc["name"]) + balance;
    }
  },

  isCOD(aid) {
    const account = this.getAccountById(aid);
    return parseInt(account["types"]) === TYPES_COD;
  },

  isSystemAccount(aid) {
    const account = this.getAccountById(aid);
    return [TYPES_COD, TYPES_DEBT].indexOf(parseInt(account["types"])) > -1;
  },

  isDebt(aid) {
    const account = this.getAccountById(aid);
    return parseInt(account["types"]) === TYPES_DEBT;
  },

  isNotAllowToCalc(aid) {
    const account = this.getAccountById(aid);
    return parseInt(account["not_allow_calc"]) === 1;
  },

  getAccountCurrency(aid) {
    const account = this.getAccountById(aid);
    return T("currency-" + (account["currency_id"] || User.get("currency_id")));
  },

  getAccountCurrencyId(accountId) {
    const account = this.getAccountById(accountId);

    return account && account["currency_id"];
  },

  getAccountById(aid) {
    const accounts = this.get("accounts");

    if (accounts === null) {
      Actions.loadAccounts();
      return [];
    }

    let account = {};
    for (let i in accounts) {
      let acc = accounts[i];
      if (parseInt(aid) === parseInt(acc["id"])) {
        account = acc;
        break;
      }
    }

    return account;
  },

  getAccountCommissionToByOidInSale(aid) {
    const account = this.getAccountById(aid)

    if(account && !!account.sales_commision && !!account.commission_to) {
      return account.commission_to;
    }
  },

  getExpensesById(eid) {
    const expenses = this.getExpensesList();

    if (expenses === null) {
      Actions.load();
      return null;
    }

    let expense = null;
    for (let i in expenses) {
      let acc = expenses[i];
      if (parseInt(eid) === parseInt(acc["id"])) {
        expense = acc;
        break;
      }
    }

    return expense;
  },

  getPersonalAccounts() {
    return this.getAccountsList([TYPES_DEBT, TYPES_COD], false, true);
  },

  getDeliveryAccounts() {
    let list = this.getAccountsList(TYPES_COD);
    console.log("LIST ", list);
    if (list.length === 0) {
      return this.getDebtAccounts();
    }

    return {
      value: list[0]["id"],
      label: list[0]["name"],
    };
  },

  getDebtAccounts() {
    let list = this.getAccountsList(TYPES_DEBT);

    if (list.length === 0) {
      User.isMainUser() &&
        TransactionsActions.addAccount({
          name: T("debt"),
          sales_commision: 0,
          balance: 0,
          typeAccount: TYPES_DEBT,
          currency_id: User.get("currency_id"),
          need_fiscal: 0,
          not_allow_calc: 1,
          id: 0,
          com_in: 0,
          com_out: 0,
        });

      return false;
    } else {
      return {
        value: list[0]["id"],
        label: list[0]["name"],
      };
    }
  },

  getAllCategory(withStatistics) {
    let category = JSON.parse(JSON.stringify(this.getCategoryList())),
      fund_category = this.getFundsList();

    for (let i in fund_category) {
      let cat = fund_category[i];
      category[cat["id"]] = cat;
    }

    if (withStatistics) {
      for (let i in category) {
        let cat = category[i];
        const expenses = this.getExpensesByCategoryId(cat["id"]);
        const getStatistics = this.getCalcStatisticsExpenses(expenses);
        cat = { ...cat, ...getStatistics };

        category[cat["id"]] = cat;
      }
    }

    return category;
  },

  getFundsList() {
    return this.getCategoryList("receipt_category");
  },

  getCategoryList(_type) {
    let type_category = _type;
    if (typeof type_category == "undefined") {
      type_category = "expenses_category";
    }
    const category = this.get(type_category);
    if (category === null) {
      Actions.loadCategory();
      return {};
    }

    return category;
  },

  getCategoryListWithoutCategory(withoutCategoryID) {
    const list = this.getCategoryList()

    if(withoutCategoryID !== undefined) {
      const filterWithProfit = Object.values(list).filter(item => {
        return parseInt(withoutCategoryID) !== parseInt(item.id);
      })

      return filterWithProfit.reduce((acc, cur) => {
        acc[cur.id] = cur
        return acc
      }, {})
    }

    return list
  },


  getCategoryForCustomizing(type) {
    const getCategory = this.getCategoryList(type);

    return Object.values(getCategory || {}).map((item, index) => ({
      id: item["id"],
      label: item["name"],
      position: index + 1,
      is_active: true,
    }));
  },

  getExpensesList() {
    const expenses = this.get("rows");
    if (expenses === null) {
      this.getCategoryList();
      Actions.load();
      return [];
    }

    return expenses;
  },

  getAmountByRate(aid, amount) {
    let currency_id = this.getAccount(aid)["currency_id"],
      rate = User.getCurrencyRates(currency_id);

    return amount * rate;
  },

  getShipmentList() {
    const expenses = this.get("rows");
    if (expenses === null) {
      this.getCategoryList();
      Actions.load();
      return [];
    }

    return expenses;
  },

  isReceipt(cat_id) {
    const fund_category = this.getFundsList();

    if (typeof fund_category[parseInt(cat_id)] === "undefined") {
      return false;
    }

    return true;
  },

  getCategoryName(cat_id) {
    let name = "";
    const category = this.getCategoryList(),
      fund_category = this.getFundsList();

    if (category && category[cat_id]) {
      name = T(category[cat_id]["name"]);
    } else if (fund_category && fund_category[cat_id]) {
      name = T(fund_category[cat_id]["name"]);
    }

    return name;
  },

  getNameTypeExpenses(types) {
    let name = "";

    if (types === 1) {
      name = T("onetime");
    } else if (types === 2) {
      name = T("planned");
    }

    return name;
  },

  getTypeCategory(cat_id) {
    if (!this.state.category || !this.state.category[cat_id]) return "";

    let name = "",
      type_id = this.state.category[cat_id]["types"];

    if (type_id === 1) {
      name = T("onetime");
    } else if (type_id === 2) {
      name = T("regular");
    }

    return name;
  },

  getProductName(pid) {
    if (!pid) {
      return T("general-expenses");
    }
  },

  getInitialState() {
    return this.state;
  },

  onLoad(force) {
    if (this.state.isStartLoaded) return false;
    this.setState({
      isStartLoaded: true,
    });

    let url = this._prefix;

    if (force) {
      url += "?t=" + Date.now();
    }

    return yajax.get(url).then(Actions.load.completed, Actions.load.failed);
  },

  onLoadCompleted(data) {
    let result = [];

    for (let i in data["data"]) {
      let d = data["data"][i];
      d["category_name"] = this.getCategoryName(d["category_id"]);
      d["is_receipt"] = this.isReceipt(d["category_id"]);
      d["product_name"] = this.getProductName(d["product_id"]);
      d["category_type"] = this.getTypeCategory(d["category_id"]);
      d["expenses_type"] = this.getNameTypeExpenses(d["types"]);

      result.push(d);
    }

    this.setState({
      isStartLoaded: false,
      rows: result,
      report: data["report"],
    });

    this.checkOnBoarding();
  },

  onLoadFailed() {
    this.setState({
      isStartLoaded: false,
    });
    console.log("failed load expenses");
  },

  onAddFundsCategory(name) {
    return yajax
      .post(this._prefix + "category", {
        name: name["name"],
        types: 0,
        is_receipt: 1,
      })
      .then(
        Actions.addFundsCategory.completed,
        Actions.addFundsCategory.failed
      );
  },
  onAddFundsCategoryCompleted(data) {
    console.log("completed add funds category ");
    Actions.loadCategory();
  },

  onAddFundsCategoryFailed() {
    console.log("failed add funds category");
  },

  onAddCategory({ name, types, is_receipt, is_profit, id }) {
    const data = { name, types, is_receipt };

    if (is_profit) {
      data.is_profit = is_profit;
    }

    if (id) {
      data.id = id;
    }

    return yajax
      .post(this._prefix + "category", data)
      .then(Actions.addCategory.completed, Actions.addCategory.failed);
  },

  onAddCategoryCompleted(data) {
    console.log("completed add category ");
    Actions.loadCategory();
  },

  onAddCategoryFailed() {
    console.log("failed add category");
  },

  onAddExpenses(
    account,
    amount,
    comment,
    types,
    category,
    shipment,
    date,
    inventory_id,
    id,
    mid,
  ) {
    let data = {
      account_id: account,
      category_id: category,
      shipment_id: shipment,
      inventory_id: inventory_id,
      amount: amount,
      date: Date.parse(date) / 1000,
      types: types,
      comment: comment,
    };
    if (id) {
      data["id"] = id;
    }

    if(mid) {
      data.mid = mid
    }

    return yajax
      .post(this._prefix, data)
      .then(Actions.addExpenses.completed, Actions.addExpenses.failed);
  },

  onAddExpensesCompleted(data) {
    console.log("completed add expenses");
    setTimeout(() => {
      Actions.load();
      Actions.loadAccounts();
      TransactionsActions.load();
    }, 100);
  },

  onAddExpensesFailed() {
    console.log("failed add expenses");
  },

  onDeleteExpenses(eid) {
    const params = {
      method: "DELETE",
      url: this._prefix,
      data: { eid: eid },
    };

    return yajax(params).then(
      Actions.deleteExpenses.completed,
      Actions.deleteExpenses.failed
    );
  },

  onDeleteExpensesCompleted(data) {
    console.log("completed delete expenses");
    Actions.load();
    TransactionsActions.load();
    Actions.loadAccounts();
  },

  onDeleteExpensesFailed() {
    console.log("failed delete expenses");
  },

  onDeleteAccount(aid) {
    const params = {
      method: "DELETE",
      url: this._prefix + "accounts",
      data: { aid: aid },
    };

    return yajax(params).then(
      Actions.deleteAccount.completed,
      Actions.deleteAccount.failed
    );
  },

  onDeleteAccountCompleted(data) {
    console.log("completed delete account");
    Actions.loadAccounts();
  },

  onDeleteAccountFailed() {
    console.log("failed delete accounts");
  },

  onTransferFunds(
    from_aid,
    to_aid,
    amount,
    oid,
    comment,
    start_amount,
    bulk,
    rates,
    commission,
    isPrepaid
  ) {
    let request = {
      from_aid: from_aid,
      to_aid: to_aid,
      amount: amount,
      comment: comment || "",
    };

    if (start_amount) {
      request["start_amount"] = start_amount;
    }

    if (rates) {
      request.rates = JSON.stringify(rates);
    }

    if (oid && typeof oid != "undefined") {
      request["oid"] = oid;
    }
    if (commission) {
      request.commission = commission;
    }

    if(isPrepaid) {
      request.isPrepaid = isPrepaid
    }

    if (bulk?.length) request = { bulk: JSON.stringify(bulk) };

    return yajax
      .post(this._prefix + "transfer", request)
      .then(Actions.transferFunds.completed, Actions.transferFunds.failed);
  },

  onTransferFundsCompleted(data) {
    console.log("completed transferFunds");
    Actions.loadAccounts();
  },

  onTransferFundsFailed() {
    console.log("failed transferFunds");
  },

  onLoadCategory(force) {
    if ((this.get("category") || this.loadingCategory) && !force) {
      return;
    }
    let args = "";

    if (force) {
      args = "?refresh=1";
    }

    this.loadingCategory = true;

    return yajax
      .get(this._prefix + "category" + args)
      .then(Actions.loadCategory.completed, Actions.loadCategory.failed);
  },

  onLoadCategoryCompleted(data) {
    let expenses_cat = {},
      receipt_cat = {};

    for (let r in data["data"]) {
      let cat = data["data"][r];
      if (parseInt(cat["is_receipt"]) === 1) {
        cat["name"] = T("receipts-category-prefix") + ": " + T(cat["name"]);
        receipt_cat[cat["id"]] = cat;
      } else {
        expenses_cat[cat["id"]] = cat;
      }
    }

    this.setState({
      expenses_category: expenses_cat,
      receipt_category: receipt_cat,
    });

    this.loadingCategory = false;
  },

  onLoadCategoryFailed() {
    console.log("failed load category");
    this.loadingCategory = false;
  },

  ifProfitInfluenceCategory(cat_id) {
    cat_id = parseInt(cat_id);
    if (
      this.state.expenses_category[cat_id] &&
      this.state.expenses_category[cat_id]["is_profit"] === 1
    ) {
      return true;
    }

    return false;
  },

  getAccount(aid) {
    if (this.state.accounts && this.state.accounts[aid]) {
      return this.state.accounts[aid];
    }

    return {};
  },

  getAccountName(aid) {
    let name = "";
    if (this.state.accounts && this.state.accounts[aid]) {
      name = this.state.accounts[aid]["name"];
    }

    return name;
  },

  getReceipt(start, end) {
    const expenses = this.getExpensesByDate(start, end);

    let amount = 0;
    for (let e in expenses) {
      let exp = expenses[e];
      if (exp["is_receipt"]) {
        let a = exp["amount"];
        if (
          this.getAccount(exp["account_id"])["currency_id"] !==
          User.get("currency_id")
        ) {
          a = this.getAmountByRate(exp["account_id"], a);
        }

        amount += a;
      }
    }

    return amount;
  },

  getCategoryById(categoryID) {
    const list = this.getCategoryList();

    return list[categoryID] || null;
  },

  getCategoryWithProfit(type) {
    const list = this.getCategoryList()

    return Object.values(list).filter(item => parseFloat(item.is_profit) === parseInt(type) && parseInt(item.id) !== 2)
  },

  getExpenseCategoryName(id) {
    const list = this.getCategoryList()

    return (list[id] && list[id]?.name) || ''
  },

  getReceiptByDate(start, end) {
    let expenses = this.getExpensesByDate(start, end);
    expenses = expenses.filter((exp) => {
      return exp["is_receipt"];
    });

    return expenses;
  },

  getAllExpenses(start, end) {
    let expenses = this.getExpensesByDate(start, end);
    expenses = expenses.filter((exp) => {
      return !exp["is_receipt"];
    });

    return expenses;
  },

  getExpensesByDate(start, end) {
    const old_start = this.state._filter["date_start"],
      old_end = this.state._filter["date_end"];

    this.state._filter["date_start"] = start;
    this.state._filter["date_end"] = end;

    const expenses = this.getExpenses();

    this.state._filter["date_start"] = old_start;
    this.state._filter["date_end"] = old_end;

    return expenses;
  },

  getAllExpensesWithoutShipment(start, end) {
    let expenses = this.getAllExpenses(start, end);
    expenses = expenses.filter((exp) => {
      return this.SHIPMENT_CATEGORY !== exp["category_id"];
    });

    return expenses;
  },

  getExpensesByCategory(start, end) {
    const expenses = this.getExpensesByDate(start, end);

    let response = {};
    for (let e in expenses) {
      let exp = expenses[e],
        amount = parseFloat(exp["amount"]);
      if (
        this.getAccount(exp["account_id"])["currency_id"] !==
        User.get("currency_id")
      ) {
        amount = this.getAmountByRate(exp["account_id"], amount);
      }

      const cat_id = exp["category_id"];

      if (!this.isReceipt(cat_id)) {
        if (!(cat_id in response)) {
          response[cat_id] = 0;
        }

        response[cat_id] += amount;
      }
    }

    var sortable = [];
    for (var vehicle in response) {
      sortable.push([vehicle, response[vehicle]]);
    }

    sortable.sort(function (a, b) {
      return b[1] - a[1];
    });

    return sortable;
  },

  getExpenses() {
    const expenses = this.getExpensesList();
    return expenses.filter(this.applyFilter);
  },

  getExpensesByCategoryId(categoryId) {
    const expenses = this.getExpenses();

    return expenses.filter(
      (item) => {
        const getAccount = this.getAccountById(item.account_id)
        if(getAccount && !!Object.keys(getAccount).length) {
          return parseInt(item.category_id) === parseInt(categoryId)
        }
        return false;
      }
    );
  },

  getCalcStatisticsExpenses(expenses) {
    if (expenses) {
      return expenses.reduce(
        (acc, cur) => {
          const getCurrency = this.getAccountCurrencyId(cur.account_id);
          if(getCurrency) {
            if (cur.is_receipt) {
              if (acc.sumReceiptsByCurrency[getCurrency]) {
                acc.sumReceiptsByCurrency[getCurrency] += cur.amount;
              } else {
                acc.sumReceiptsByCurrency[getCurrency] = cur.amount;
              }
            } else {
              if (acc.sumExpensesByCurrency[getCurrency]) {
                acc.sumExpensesByCurrency[getCurrency] += cur.amount;
              } else {
                acc.sumExpensesByCurrency[getCurrency] = cur.amount;
              }
            }
          }

          return acc;
        },
        {
          sumExpensesByCurrency: {},
          sumReceiptsByCurrency: {},
          count: expenses.length,
        }
      );
    }
    return {};
  },

  onLoadAccounts(force) {
    if (this.loadingAccounts && !force) return this.loadingAccounts;

    // console.log("LOAD ACCOUNTS ", User.isGuest(), User.getToken());
    this.loadingAccounts = yajax
      .get(this._prefix + "accounts")
      .then(Actions.loadAccounts.completed, Actions.loadAccounts.failed);

    return this.loadingAccounts;
  },

  onLoadAccountsCompleted(data) {
    this.loadingAccounts = false;

    const accounts = {...data['data']}

    const notPaidLocales = {
      ru: "Не оплачено",
      en: "Not paid",
      ua: "Не оплачено"
    }

    const cashOnDelivery = {
      ru: "Наложенный платеж",
      en: "COD",
      ua: "Післяплата"
    }

    Object.keys(accounts).forEach((accountId) => {
      const name = accounts[accountId].name;

      if(name === 'Не оплачено' || name === 'Not paid' || name === 'Debt') {
        if(notPaidLocales[UserStore.getLang()]) {
          accounts[accountId].name = notPaidLocales[UserStore.getLang()];
        }
      } else if (name === 'Наложка' || name === "COD" || name === 'Післяплата' || name === "Наложенный платеж") {
        if(notPaidLocales[UserStore.getLang()]) {
          accounts[accountId].name = cashOnDelivery[UserStore.getLang()];
        }
      }
    })

    this.setState({
      accounts: data["data"],
    });
  },

  onLoadAccountsFailed() {
    this.loadingAccounts = false;
    console.log("failed load accounts");
  },
});

export default ExpensesStore;
