import React from 'react'
import {Dropdown} from "react-bulma-components";
import T from "../../../../components/i18n";
import AppStore from "../../../../app-store";
import DocumentModal
	from "../../../../components/document-modal/document-modal";
import { LocalIcon } from "whitelables/wl-name/icons";
import ClientsStore from "../../../clients/clients-store";
import numberFormat from "../../../../tools/number-format";
import UserStore from "../../../../user/user-store";
import WarehouseStore from "../../../warehouse/warehouse-store";
import SalesStore from "../../../sales/sales-store";

const DropdownDocument = ({data, activeFilter, labelDate}) => {
	let COLUMNS = [
		"name",
		"sales-amounts",
		"dashboard-sales-profit",
		"sales",
		"average-check",
		"many-margin",
	];

	if (activeFilter === "mid") {
		COLUMNS.push("Expenses");
	}

	if(activeFilter === "clients") {
		COLUMNS = [
			...COLUMNS.slice(0, 1),
			'phone',
			...COLUMNS.slice(1)
		];
	}


	let headingName = T(activeFilter)

	if(activeFilter === 'mid') {
		headingName = T('history-data-mids')
	} else if(activeFilter === 'channels') {
		headingName = T('history-data-mids')
	}

	const heading = `${headingName} ${labelDate}`

	const generateList = data.map(item => {
		let name = item.name
		let phone = ''

		if (activeFilter === "mid") {
			name = WarehouseStore.getWarehouseName(item.name);
		} else if (activeFilter === "clients") {
			const client = ClientsStore.getClientById(item.name);
			name = client?.simple_name || item.name;
			phone = client?.phone ? `+${client.phone}` : ''
		} else if (activeFilter === "channels") {
			const channelName = SalesStore.getChannelsName(item.name);
			name = T(channelName);
		}

		const KEY_MAP = {
			"name": name,
			"phone": phone,
			"sales-amounts": `${numberFormat(item?.amount_sale || 0)} ${UserStore.getCurrency()}`,
			"dashboard-sales-profit": `${numberFormat(item?.profit || 0 - item?.expensesProfit || 0)} ${UserStore.getCurrency()}`,
			"sales": `${item?.count || 0} (${item?.quantity || 0} ${T('items-e')})`,
			"average-check": `${numberFormat(item?.averageCheck)}  ${UserStore.getCurrency()}`,
			"many-margin": `${item?.margin}%`,
			"Expenses": `${T("expenses-shipment")}: ${numberFormat(item?.shipmentExpenses || 0)} ${UserStore.getCurrency()}
				\n
				${T('expenses-profit-sales')}: ${numberFormat(data?.expensesProfit || 0)} ${UserStore.getCurrency()}`,
		}

		return COLUMNS.map((item) => {
			if(typeof KEY_MAP[item] === 'function') {
				return KEY_MAP[item]()
			} else {
				return KEY_MAP[item]
			}
		})
	})

	const documentList = {
		"head": {
			"title": heading,
			"table_cols": COLUMNS.map(item => T(item)),
			"table_widths_cols": COLUMNS.map((item, index) => index === 0 ? 3 : 1),
			"orientation": 1
		},
		"table": generateList,
	}

	return (
		<Dropdown right={true} label={<LocalIcon icon="download" size="large" />}>
			<Dropdown.Item
				target="_blank"
				title={T("download-report-pdf")}
				value="item"
			>
				<div onClick={() => AppStore.openModal(<DocumentModal printDocument={documentList} format='pdf' heading={heading}/>)}>
					{T("download-report-pdf")}
				</div>
			</Dropdown.Item>
			<Dropdown.Item
				title={T("download-report-excel")}
				value="item"
			>
				<div onClick={() => AppStore.openModal(<DocumentModal printDocument={documentList} format='xls' heading='test'/>)}>
					{T("download-report-excel")}
				</div>
			</Dropdown.Item>
		</Dropdown>
	);
};

export default DropdownDocument;