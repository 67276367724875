import MessageFormats from "./message-formats";
import { AVAILABLE_LANGS } from "configs/i18n";

const getLangLocaleStorage = window.localStorage.getItem("lang")
const WHITELABEL = process.env.REACT_APP_WHITELABLE;
let _currentLang = getLangLocaleStorage ? getLangLocaleStorage : WHITELABEL === 'modeler' ? 'ru' : "ua";

let _allMessages = {};

export default function translate(s, context) {
    let _lang = _currentLang || "ua";
        let translation = _allMessages && _allMessages[s];

        if (translation && translation.plural) {
            _allMessages[s] = translation = MessageFormats.compile(translation.plural, _lang);
        }

        if (translation && translation.var) {
            _allMessages[s] = translation = MessageFormats.compile(translation.var, _lang);
        }

        if (translation === void 0) {
            translation = s;
        }

        return typeof translation === "function" ? translation(context || {}) : translation;
}

export const hasMessage =(s) => !!(_allMessages[s] && _allMessages[s][_currentLang]);
export const setLang = (lang) => { 
    console.log("SET LANG, ", lang)
    if (lang in AVAILABLE_LANGS) {
        _currentLang = lang; 
    }
};
export const getLang = () => _currentLang;
export const setMessages = (messages) => { _allMessages = messages; };
export const merge = (dst, src) => {
    for (const key in src) {
        if (src.hasOwnProperty(key)) {
            if (dst.hasOwnProperty(key)) {
                throw new Error("i18n conflict: " + key + " [" + dst[key] + "] replace with [" + src[key] + "]");
            }
            dst[key] = src[key];
        }
    }

    return dst;
};

