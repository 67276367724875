import React from "react";
import { Input } from "react-bulma-components/lib/components/form";

export const checkValue = (value, withNegative, max) => {
  let val = value.trim().replace(/\s+/g, '');
  let lastChar = val[val.length - 1];
  if ((val === "-" || val === '0-') && withNegative) {
    return "-";
  }

  if(!withNegative) {
    val = val.replace('-', '')
  }

  if (lastChar === "," || lastChar === "." || lastChar === "0") {
    val = val.replace(",", ".");
    let count = val.split(".").length - 1;

    if (count > 1) {
      val = val.slice(0, -1);
    }
    if (val && val.length > 1 && val[0] === "0" && val[1] !== ".") {
      val = val.slice(1);
    }
  } else {
    if (withNegative && val.startsWith("-")) {
      val = val.slice(1);
    }

    val = parseFloat(val);

    if (isNaN(val)) {
      val = "";
    } else if (withNegative && value.startsWith("-")) {
      val = -val;
    }
  }

  if(!isNaN(val) && max && parseFloat(val) > parseFloat(max)) {
    return max;
  }

  return val;
}

const NumberInput = ({ className,max,withNegative, onChange, ...props }) => {
  const handleChange = (e) => {
    const { value } = e.target;
    e.target.value = checkValue(value, withNegative,max)
    onChange && onChange(e);
  };

  return (
    <Input
      onChange={handleChange}
      className={className}
      {...props}
      type="text"
    />
  );
};

export default NumberInput;
