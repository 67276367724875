import {setMessages, setLang} from "../components/i18n";
import { AVAILABLE_LANGS } from "./wl-name/api"

export { AVAILABLE_LANGS } from "./wl-name/api"




function getDefaultLang() {
    if (process.env.FORCE_DEFAULT_LANG) {
        return process.env.FORCE_DEFAULT_LANG;
    }

    if ((window.location.search.match(/lang=(\w+)/i) || [])[1]) {
        return (window.location.search.match(/lang=(\w+)/i) || [])[1];
    }

    let path_a = window.location.pathname.split("/");

    const isApp = path_a.find(item => item === 'app')

    for (let l in AVAILABLE_LANGS) {
            if (!l) {continue;}

            for (let i in path_a) {
                let la = path_a[i]
                if (!la || la === "") continue;

                if (la === l) {
                    if(!isApp) {
                        window.localStorage.setItem('lang', l);
                        return l;
                    }
                }
            }
    }

    let localStorageLand = window.localStorage.getItem("lang");
    if (localStorageLand) {
        return localStorageLand;
    }

    let lang = isApp ? 'ua' : "ru";
    const navigatorLanguage = typeof navigator !== "undefined" && (navigator.language || navigator.browserLanguage);

    if (navigatorLanguage) {
        lang = navigatorLanguage.split("-")[0];

        if (["ua", "uk", "en", "ru"].indexOf(lang)) {
            if (lang === "uk") {
                lang = "ua";
            }
        } else {
            lang = ["kk", "lt", "lv", "et", "uz", "az", "hy", "ka", "tt", "ky", "tk", "md", "tg"].indexOf(lang) !== -1 ? isApp ? 'ua' : "ru" : "en";
        }
    }

    window.localStorage.setItem('lang', lang);
    return lang;
}

export const DEFAULT_LANG = getDefaultLang();

export async function loadMessages(lang) {
    try {

        let messages
        if (lang === 'ru') {
            messages = await import('../components/i18n/locales_ru.js');
        } else if (lang === 'ua') {
            messages = await import('../components/i18n/locales_ua.js');
        } else if(lang === 'en') {
            messages = await import('../components/i18n/locales_en.js');
        }

        setMessages(messages.messages);
        setLang(lang)
        window.I18N_LOADED = true;
    } catch (error) {
        console.error("Ошибка загрузки языка:", error);
    }
}


// setMessages(messages);
// window.I18N_LOADED = true;
