import React from "react";
import UserStore from "user/user-store";
import Sidebar from "templates/sidebar.js";
import AppStore from "app-store";
import { Link } from "react-router-dom";
import { LocalIcon } from "whitelables/wl-name/icons";
import SidebarTarif from "../../templates/sidebar-tarif";
import {Button} from "react-bulma-components";
import s from './styles.module.scss'

const WrapperSidebar = ({ showCollapseMenu, changeCollapse }) => {
  return (
		<div className="wrapper-sidebar">
			<div className='display-flex-column' style={{height: '100%'}}>
				<div className="account-logo">
					<Link to="/">
						<img
							src={AppStore.getImagesPath("logo.webp")}
							alt={AppStore.getAppName()}
							width="153px"
						/>
					</Link>
				</div>

				<div className="account-logo-collapse">
					<Link to="/">
						<img
							src={AppStore.getImagesPath("logo192.png")}
							alt={AppStore.getAppName()}
							width="40px"
						/>
					</Link>
				</div>
				<div className="user-info">
					ID: {UserStore.getUserId()}
					<br/>
					<span>
            {UserStore.getMyGroup()} <br/>
						{UserStore.get("first_name")}{" "}{UserStore.get("last_name")}
          </span>
				</div>
				<Sidebar showCollapseMenu={showCollapseMenu()}/>
				{!showCollapseMenu() && UserStore.isMainUser() && <SidebarTarif/>}
			</div>
			<div className={`${s.collapse} ${showCollapseMenu() ? s.rotate : ''}`}>
				<Button className='button-link-without-style' style={{color: '#383a3d'}}
								onClick={changeCollapse}>
					<LocalIcon icon='left-arrow' size='small'/>
				</Button>
			</div>
		</div>
	);
};

export default WrapperSidebar;
