import React, {useEffect, useState} from "react";
import { connectToStores } from "tools/reflux-tools";
import HistoryStore from "./history-store";
import HistoryActions from "./history-actions";
import HistoryContent from "./history-conent";
import DynamicTabList from "../../components/dynamic-tab-list/dynamic-tab-list";
import ProductsDeleted from "./products-deleted";
import StockMarketplaces from "./stock-marketplaces/stock-marketplaces";
import SalesDeleted from "./sales-deleted";
import ProductsStore from "../products/products-store";

const list = [
  {
    value: 'history',
    label: "history",
  },
  {value: 'stock-marketplaces', label: "stock-marketplaces" },
  {value: 'products-deleted', label: "products-deleted" },
  {value: 'sales-deleted', label: "sales-deleted" },
]

const History = () => {
  const [isTab, setIsTab] = useState('history')

  useEffect(() => {
    HistoryActions.load();
    return () => {
      HistoryStore.clearFilter()
    }
  }, []);

  const handleChangeTab = (tab) => {
    setIsTab(tab)
  }

  return (
    <>
      <DynamicTabList
        list={list}
        defaultTab={isTab}
        onChangeTab={handleChangeTab}
      />
      {isTab === 'history' && <HistoryContent/>}
      {isTab === 'products-deleted' && <ProductsDeleted/>}
      {isTab === 'stock-marketplaces' && <StockMarketplaces/>}
      {isTab === 'sales-deleted' && <SalesDeleted/>}
    </>
  );
};

export default connectToStores(History, { _: HistoryStore, ProductsStore });
