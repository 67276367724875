import React, { Component } from 'react';

import AddSaleModal from "./add-sales-modal";
import SalesStore from './sales-store';
import UserStore from "../../user/user-store";
import AppStore from "../../app-store";
import HistoryStore from "../history/history-store";

// import OpenModal from 'components/modal/modal';

class EditSalesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentWillUnmount() {
        AppStore.setState({
            notificationClose: false
        })
    }

    render() {
        const { oid } = this.props

        let sales = SalesStore.getSalesByOid(oid);

        if(this.props.isRefund) {
            sales = SalesStore.getRefundById(oid);
        } else if (this.props.isDeleted) {
            sales = HistoryStore.getDeletedSalesById(oid);
        }

        const modification = UserStore.getPermissionEnable("edit-sales")

        const handleChangeSale = (isChange) => {
            AppStore.setState({
                notificationClose: isChange
            })
        }



        return (
            <AddSaleModal resetDate={this.props.resetDate} className='modal-start' {...sales} type_sale={this.props.type_sale} onChangeSale={handleChangeSale} modification={modification} isEdit={true} isRefund={this.props.isRefund} modal="sale-modal" isDeleted={this.props.isDeleted}/>
        );
    }
}


export default EditSalesModal;