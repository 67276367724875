import React, {useEffect, useState} from 'react'
import {Box, Button, Heading} from "react-bulma-components";
import InputsGroupField
	from "../../integrations-nw/modal/common/inputs-group-field";
import UserStore from "../../../../user/user-store";
import T from '../../../../components/i18n'
import {
	Checkbox,
	Field
} from "react-bulma-components/lib/components/form";
import SubmitButton from "../../../../components/submit-button";
import isValidPhone from "../../../../tools/valid-phone";
import UserActions from "../../../../user/user-actions";
import ErrorMessageControlHook
	from "../../../../components/error-message-block/error-message-control.hook";
import isValidEmail from "../../../../tools/valid-email";
import {errorHandler} from "../../../../tools/error-handler";

const list = [
	{
		label: "client-name",
		name: "first_name",
	},
	{
		label: "last-name",
		name: "last_name",
	},
]

const EditUserProfile = ({onClose}) => {
	const [values, setValues] = useState({
		email: UserStore.get("email") || "",
		phone: UserStore.get("phone") || "",
		first_name: UserStore.get("first_name") || "",
		last_name: UserStore.get("last_name") || "",
		send_email: Boolean(UserStore.get("send_email"))
	})
	const [waiting, setWaiting] = useState(false)

	const {setErrorMessage,ErrorMessageBlock,errorMessage,handleAutoRemoveError} = ErrorMessageControlHook()

	const handleChangeValue = ({target: {value, name, checked}}) => {
		let newValue = value;

		if(name === 'phone' && isNaN(value)) {
			return
		}
		if(name === 'send_email') {
			newValue = checked;
		}

		setValues(prev => ({...prev, [name]: newValue}));
	}

	const handleSubmit = () => {
		const {email,first_name,last_name,send_email,phone} = values

		if(phone && !isValidPhone(phone)) {
			handleAutoRemoveError('wrong-phone')
		}
		if(!isValidEmail(email)) {
			handleAutoRemoveError('wrong-email')
		}

		setWaiting(true)
		UserActions.editUserProfile({email,first_name,last_name,send_email: Number(send_email),phone})
	}

	useEffect(() => {
		const edit = UserActions.editUserProfile.completed.listen(() => {
			onClose()
		})
		const editFail = UserActions.editUserProfile.failed.listen((res) => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})

		return () => {
			editFail()
			edit()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])


	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<Box>
				<Heading>{T('editing-personal-information')}</Heading>
				<InputsGroupField dataInput={list} onChange={handleChangeValue} inputValues={values}/>
				<Field>
					<Checkbox name="send_email" checked={values.send_email} onChange={handleChangeValue}>
						{T('subscribe-email')}
					</Checkbox>
				</Field>
				<div className='display-flex-row' style={{justifyContent: 'flex-end'}}>
					<Button size='medium' rounded onClick={onClose}>{T('close')}</Button>
					<SubmitButton color='success' size='medium' fullwidth={false} text='btn-edit' submit={handleSubmit} waiting={waiting}/>
				</div>
			</Box>
		</>
	);
};

export default EditUserProfile;