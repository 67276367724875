import React, {useEffect, useState} from "react";
import T from "../../../../../components/i18n";
import { List } from "react-bulma-components";
import UserStore from "../../../../../user/user-store";
import formatDate from "../../../../../tools/format-date";
import {parseComment} from "./utils";
import { LocalIcon } from 'whitelables/wl-name/icons'
import WarehouseStore from "../../../../warehouse/warehouse-store";
import AppStore from "../../../../../app-store";
import SaleViewModal from "../../../../sales/modal/sale-view/sale-view-modal";
import Button from "react-bulma-components/lib/components/button";
import {
  useIsMobile
} from "../../../../../components/is-mobile-context/is-mobile-context";

const HistoryProductRow = ({ data, triggerOpen }) => {
  const {isMobileSize} = useIsMobile()
  const [isOpen, setIsOpen] = useState(false)
  const comment = parseComment(data.comment)

  let marketplace

  if (data['marketplace_id']) {
    marketplace = (
      <span><b>{T('history-data-marketplace_id')}:</b> <span className='nowrap'>{T(WarehouseStore.getWarehouseName(data['marketplace_id']))}</span></span>
    )
  }

  let shipment_number

  if (data['shipment_id'] && data['shipment_id'] !== "null") {
    if (data['shipment_id'].indexOf("[") > -1) {
      let shipment = JSON.parse(data['shipment_id']);
      shipment_number = [];
      for (let i in shipment) {
        let sp = shipment[i];
        shipment_number.push(
          <div key={`shipnumber-${sp['sid']}`}><b>{T('shipment')} #{sp['sid']}</b>. {T('quantity')}: {sp['count']}</div>
        )
      }
    } else {
      shipment_number = (
        <div><b>{T('shipment')}:</b> #{data['shipment_id']}</div>
      )
    }
  }

  let order_number

  if (data['order_id']) {
    let comments = {}
    if (data['comment'][0] === "{") {
      comments = JSON.parse(data['comment']);
    }
    order_number = (
      <div><b>{T('purchase')}: </b><Button className='button-link-without-style' onClick={() => AppStore.openModal(<SaleViewModal modal='sale-view-modal' saleID={data['order_id']}/>)}>#{comments['order_number'] || data['order_id']}</Button></div>
    )
  }

  useEffect(() => {
    setIsOpen(triggerOpen)
  }, [triggerOpen]);

  return (
    <>
      <List.Item className={`display-flex-row-gap cursor-pointer ${isOpen ? 'selected-border' : ''}`}
                 style={{alignItems: "center", borderRadius: 0, borderBottom: isOpen ? '1px solid transparent' : '', flexWrap: 'wrap'}}
                 onClick={() => !!comment.length && setIsOpen(!isOpen)}
      >
        <div className='display-flex-row '>
          <div
            className={`display-flex-center black icon-open ${isOpen ? 'active' : ''}`} style={{minWidth: 13}}>
            {!!comment.length && <LocalIcon icon='arrow-left' size='small'/>}
          </div>
          <div className="display-flex-column">
            <small>{formatDate(data["created_at"])}</small>
            <b style={{color: "#48c774"}}>
              {T("history-action-" + data["action_id"])}
            </b>
            {shipment_number}
            {order_number}
          </div>
        </div>
        <div className='display-flex-column'>
          {marketplace}
          <b>
            {T("responseble")}:{" "}
            <span className='nowrap'>
              {UserStore.getResponsebleName(data.responsible_user_id)}
            </span>
          </b>
        </div>

      </List.Item>
      {isOpen && <List.Item className='selected-border' style={{borderRadius: 0}}
                            >
        <div className='display-flex-row flex-wrap' style={{justifyContent: "space-between"}}>
          {comment?.map(([key, value]) => <div style={isMobileSize ? {flexBasis: '48%'} : {flexBasis: "30%"}} key={key}>
            {key && <b>{key}:</b>} {value}</div>)}
        </div>
      </List.Item>}
    </>

  );
};

export default HistoryProductRow;
