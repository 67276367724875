import React from 'react'
import BaseFilterLayout from "../../../components/base-filter-layout";
import ClientsStore from "../clients-store";
import ClientsFilter from "../clients-filter";
import ClientsActions from "../clients-actions";
import DropdownDocument
	from "../../../components/document-modal/dropdown-document";
import numberFormat from "../../../tools/number-format";
import UserStore from "../../../user/user-store";
import formatDate from "../../../tools/format-date";
import T from "../../../components/i18n";
import {formattedDate} from "../../../tools/formatted-date-dd-mm-yy";

const DATA_SORT = [
	{label: "clients-date", value: "created_at"},
	{label: "clients-name", value: "name"},
	{label: "clients-discount", value: "discount"},
	{label: "clients-total_amount", value: "total_amount"},
	{label: "clients-debt", value: "debt"},
	{label: "clients-balance", value: 'balance'}
]

let COLUMNS = [
	"clients-date",
	"clients-name",
	"clients-discount",
	"clients-total_amount",
	"clients-debt",
	"clients-balance",
	"reports-actions"
];

const FilterPanel = ({list}) => {
	const view = ['city', "tags", "total_amount_from",
		"total_amount_to", "debt_from",'debt_to']


	const handleChangeFilter = ({name, value}) => {
		if(name) {
			ClientsActions.filter(name, value);
		}
	}

	const handleChangeDate = (value) => {
		const daysKey = Object.keys(value).reverse()
		daysKey.forEach(item => {
			if(value[item]) {
				ClientsActions.filter(item, value[item]);
			}
		})
	}

	const handleRefreshFilter = () => {
		ClientsStore.clearFilter()
		ClientsActions.load(true)
	}

	if (!UserStore.getPermissionEnable('show-cashflow')) {
		COLUMNS = COLUMNS.filter(item => item !== "clients-total_amount")
	}


	const filterColumns = COLUMNS.filter(item => item !== "reports-actions")

	const generateList = list.map(data => {
		const KEY_MAP = {
			"clients-date": formatDate(data["created_at"]),
			"clients-name": data["name"],
			"clients-discount":`${data["discount"] || 0}%`,
			"clients-total_amount": `${numberFormat(data["total_amount"])} ${UserStore.getCurrency()}`,
			"clients-debt": `${numberFormat(data["debt"])} ${UserStore.getCurrency()}`,
			"clients-balance": `${numberFormat(data["balance"])} ${UserStore.getCurrency()}`,
		}

		return filterColumns.map((item) => {
			if(typeof KEY_MAP[item] === 'function') {
				return KEY_MAP[item]()
			} else {
				return KEY_MAP[item]
			}
		})
	})

	const heading = `${T('clients')} ${formattedDate(ClientsStore.getFilter("date_start"))} - ${formattedDate(ClientsStore.getFilter("date_end"))}`

	const documentList = {
		"head": {
			"title": heading,
			"table_cols": filterColumns.map(item => T(item)),
			"table_widths_cols": filterColumns.map((item, index) => index === 1 ? 3 : 1),
			"orientation": 1
		},
		"table": generateList,
	}

	return (
		<BaseFilterLayout
			searchStringSetting={{
				onChange: handleChangeFilter,
				defaultValue: ClientsStore.getFilter("search")
			}}
			refresh={{
				onChange: handleRefreshFilter
			}}
			dateRangeSetting={{
				onChange: handleChangeDate,
				date_start: ClientsStore.getFilter("date_start"),
				date_end: ClientsStore.getFilter("date_end"),
				range: ['today', 'yesterday', 'week', 'this-month', 'last-month', '3-month', 'all-period']
			}}
			sortSetting={{
				data:DATA_SORT,
				defaultActive: ClientsStore.getFilter('sort'),
				onChange:(value) => ClientsStore.onFilter('sort', value)
			}}
			filterView={{
				list: ClientsStore.get('_filter'),
				view,
				onDelete: ClientsStore.filterClearField
			}}
			hiddenFilter={<ClientsFilter/>}
			additionalSetting={<DropdownDocument list={documentList} heading={heading}/>}
		/>)
};

export default FilterPanel;