import React from "react";
import List from "react-bulma-components/lib/components/list";
import { Link } from "react-router-dom";
import AppStore from "../../../app-store";
import Image from "react-bulma-components/lib/components/image";
import T from "../../../components/i18n";
import User from "../../../user/user-store";
import BindingProduct from "../binding-product";
import { bindingSubmit } from "../common/actions-func";
import AddProductsModal from "../../products/modal/add-product/add-products-modal";
import s from "./styles.module.scss";
import Button from "react-bulma-components/lib/components/button";
import ViewProductModal from "../../products/modal/view-product/view-product-modal";
import OrdersStore from "../orders-store";
import OrdersActions from "../orders-actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {confirmDelete} from "../../../tools/confirm-delete";
import ProductsStore from "../../products/products-store";
import UserStore from "../../../user/user-store";
import {extractNumber} from "../../../tools/extract-number";

const OrderProductRowMobile = ({ data, status, integrationID, oid, currency, order_id, sku }) => {
  const { local_product_id, name, price, product_id, quantity } =
    data;

  const pid = local_product_id ? local_product_id : null;

  const nameRender = pid ? (
    <Link
      to="#"
      onClick={() => AppStore.openModal(<ViewProductModal pid={pid} />)}
    >
      {name}
    </Link>
  ) : (
    name
  );

  const img = "/bimages/get/64-" + User.get("id") + "-" + pid + "-0.jpg";

  const handleBindingProduct = () => {
    AppStore.openModal(
      <BindingProduct
        data={{
          name: name,
          remote_id: product_id,
          integration_id: integrationID,
        }}
        submit={bindingSubmit}
      />
    );
  };

  const handleCreateProduct = () => {
    const integrationInfo = UserStore.getIntegrationById(integrationID);

    AppStore.openModal(<AddProductsModal className='modal-start' name={name} vendorCode={sku || null} price={parseFloat(price)} mid={integrationInfo.ident.mid}/>);
  };

  const is_reserved = OrdersStore.getIsReserveOrdersByOidPid(oid, pid);

  const handleDeleteReserveProduct = () => {
      if(is_reserved) {
        const message = <div>{pid ? ProductsStore.getProductName(pid, true) : name} <b>{quantity} {T("items-p")}</b></div>
        confirmDelete(() => OrdersActions.deleteReserve([{pid: is_reserved.product_id, mid: is_reserved.marketplace_id, roid: order_id }]), 'confirm-delete-reserve',message, 'confirm-order-shift', 'cancel-label')
      }
  }

  return (
    <List.Item style={{ position: "relative" }}>
      <div className={s.column_name}>
         <span>
          <Image src={img} size={32} />
         </span>
         <span className={s.sub_row_name}>
            {nameRender}
            {is_reserved && status !== "completed" && (
              <b>{T("status-reserve_sale")}</b>
            )}
        </span>
        {is_reserved && status !== "completed" && (
          <div className='display-flex-row'>
            <Button onClick={handleDeleteReserveProduct} className='button-link-icon' title={T('remove-reserv')}>
              <FontAwesomeIcon icon='undo'/>
            </Button>
          </div>
        )}
      </div>
      <div className={s.column_info}>
        <span>
          <b>{T("netprice-price")}: </b>
          {extractNumber(price)} {currency}
        </span>
        <span>
          <b>{T("quantity")}: </b>
          {quantity}
          {T("items-p")}
        </span>
      </div>
      {!pid && (
        <div
          className="has-text-danger margin-bottom-10 font-size-80"
          align="center"
        >
          <b>{T("not-sync-product")}</b>
        </div>
      )}
      <Button.Group position="centered">
        {!pid && (
          <Button
            size="small"
            rounded
            color="info"
            onClick={handleBindingProduct}
          >
            {T("binding-products")}
          </Button>
        )}
        {!pid && (
          <Button
            size="small"
            rounded
            color="success"
            onClick={handleCreateProduct}
          >
            {T("add-new-product-btn")}
          </Button>
        )}
      </Button.Group>
    </List.Item>
  );
};

export default OrderProductRowMobile;
