import React from 'react';
import { connectToStores } from "tools/reflux-tools";

import T from "components/i18n";
import Actions from 'modules/orders/orders-actions'
import OrdersStore from 'modules/orders/orders-store'

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import SubmitButton from 'components/submit-button';
import Messages from 'components/messages';
import { Control, Field, Input, Label, Radio } from 'react-bulma-components/lib/components/form';
import BaseForm from 'components/modules/base-form';
import { confirmAlert } from 'react-confirm-alert';
import SalesStore from './sales-store';
import Heading from 'react-bulma-components/lib/components/heading';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import Content from 'react-bulma-components/lib/components/content';
import {
    withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";



class AddStatusModal extends BaseForm {
    constructor(props) {
        super(props);

        const statusData = OrdersStore.getStatusById(props.id)
        this.state = {
            new_status: null,
            status: {
                error: false,
                value: props.name || statusData?.name || ""
            },
            id: props.id || 0,
            type: statusData?.type || props.defaultType || this.props.location?.state?.type || null,
            message: null,
            waiting: null,
            close: false,
            isFinish: false,
            showDeleteBtn: props.showDeleteBtn || false
        };
        this.isDisabled = Boolean(props.defaultType || this.props.location?.state?.type);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const { onClose } = this.props;
        this.addStatus = Actions.addStatus.completed.listen(() => {
            if (!this.props.id) {
                this.setState({
                'isFinish': true
            });
            }
            this.setState({
                'waiting': false,
            });
            let timeout = 3000;
            if (this.props.id) {
                timeout = 500
            }
            if (typeof(onClose) == "function") {
                setTimeout(() => { onClose() }, timeout);
            }
        });
        this.addStatusFailed = Actions.addStatus.failed.listen((res) => {
            const data = JSON.parse(res['response']);
            this.setState({
                'waiting': false,
                'message': {
                    'msg': T(data['error'])
                },
            })
        }); 
        this.deleteStatus = Actions.deleteStatus.completed.listen((data) => {
            this.setState({
                'waiting': false,
            });
            if (typeof(onClose) == "function") {
                setTimeout(() => {onClose()}, 3000);
            }
        
        });
        this.deleteStatusFailed = Actions.deleteStatus.failed.listen((res) => {
            const data = JSON.parse(res['response']);
            this.setState({
                'waiting': false,
                'message': {
                'msg': T(data['error'])
                }
            })
        }); 
    }

    componentWillUnmount() {
        if (typeof (this.addStatus) === "function") {
            this.addStatus();
        }

        if (typeof(this.deleteStatus) === "function") {
            this.deleteStatus();
        }
        if (typeof (this.addStatusFailed) === "function") {
            this.addStatusFailed();
        }

        if (typeof(this.deleteStatusFailed) === "function") {
            this.deleteStatusFailed();
        }
    }

    submit() {
        if (this.state.status.value.trim().length === 0) {
        this.setState({
            status: { ...this.state.status, error: true },
            message: T("not-valid-status-name-error")
        });
        return
        }

        if (!this.state.type) {
            this.setState({
            message: T("choose-status-type-error")
        });
        return
        }

        this.setState({ "waiting": true });
        const data = { name: this.state.status.value.trim(), types: this.state.type}
        if (this.state.id) { data['id'] = this.state.id; }

        Actions.addStatus(data)
    }

    delete(id) {
        const currentStatuses = SalesStore.getCountDelivery();
        if (currentStatuses[id] >= 1) {
            this.setState({
                message: T('you-cant-delete-status-in-use')
            });
            return
        }

        confirmAlert({
            title: T('confirm-delete'),
            message: T('are-you-sure-to-delete-this-status'),
            buttons: [
            {
                label: T('delete'),
                onClick: () => Actions.deleteStatus(id)
            },
            {
                label: T('no-delete'),
                onClick: () => {}
            }
            ]
        });
    }

    navigateToSales() {
        this.props.history.replace('/sales')
        this.props.history.push('/sales/add-sale')
    }

    checkHasOrders() {
        const id = this.state.id;
        if (!id) {return false}

        const orders = OrdersStore.get("statuses");
        const hasOrders = orders.hasOwnProperty(id);
        return hasOrders
    }

    checkHasSales() {
        const id = this.state.id;
        if (!id) {return false}

        const sales = SalesStore.getCountDelivery();
        const hasSales = sales.hasOwnProperty(id);
        return hasSales
    }

    onChangeRadio(evt) {
        if (evt.target.value === "sales") {
            const hasOrders = this.checkHasOrders();
            !hasOrders ? this.setState({type: "sales"}) : this.setState({message: T("status-has-orders-error")});
        } else if (evt.target.value === "orders") {
            const hasSales = this.checkHasSales();
            !hasSales ? this.setState({type: "orders"}) : this.setState({message: T("status-has-sales-error")});
        }
    }

    renderMain() {
        const { onClose } = this.props;
        return (
                <Box>
                    <Messages message={this.state.message} close={() => this.setState({message: null})} />
                    <Field>
                <Control>
                    <Label>{this.state.id ? T('change-order-status') : T('add-status')}</Label>
                        <Input  size="large" 
                        name="status" 
                        type="text" 
                        placeholder={T('add-status-placeholder')}
                        autoComplete="nope"
                        onChange={(e) => this.onChange(e)}
                        color="primary"        
                        value={this.state.status.value}
                        />
                </Control>
                </Field>
                    <Field>
                        <Control>
                        <Label>{T('status-type')}</Label>
                            <Radio
                                className="display-flex-radio"
                                onChange={(e) => this.onChangeRadio(e)}
                                checked={this.state.type === "sales"}
                                value="sales"
                                size="large"
                                name="sale"
                                disabled={this.isDisabled}
                            >
                                <span>{T("sales-status")}</span>
                            </Radio>
                            <Radio
                                className="display-flex-radio"
                                onChange={(e) => this.onChangeRadio(e)}
                                checked={this.state.type === "orders"}
                                value="orders"
                                size="large"
                                name="orders"
                                disabled={this.isDisabled}
                            >
                                <span>{T("orders-status")}</span>
                            </Radio>
                        </Control>
                    </Field>
                    <Button.Group position="centered">
                    <SubmitButton fullwidth={false} text={this.state.id ? 'change-order-status' : 'add-status'} waiting={this.state.waiting} submit={this.submit} />
                    {this.state.id && this.state.showDeleteBtn ? <Button size="large" rounded color="primary" onClick={() => this.delete(this.state.id)}
                    >{T('delete')}</Button> : null}
                    <Button  size="large"  rounded color="light"
                    onClick={() => {onClose()}}>{T('close')}</Button>
                    </Button.Group>
                </Box>
        );
    }
    
    renderFinish() {
        return (
        <Box align="center">
            <div className="modal-icon">
                <img src="/img/icons/ok.png" alt="print-icon" width="88px" border="0"/>
            </div>
                <Heading>{T('status-successfully-added')}</Heading>
                <Content size="medium">{T('status-successfully-added-2')}</Content>
                <Button size={this.props.isMobileSize ? "small": "medium"} className="margin-bottom-10" fullwidth={true} rounded color="primary"
                onClick={() => this.navigateToSales()}
                >{T('add-sale-btn')}</Button> 
        </Box>
        );
    }

    render() {
        return (<div>{!this.state.isFinish ? this.renderMain() :
            !this.state.id ? this.renderFinish() : null}</div>)
    }  
}

export default connectToStores(withIsMobileSize(withRouter(AddStatusModal)), {
    orders: OrdersStore, 
});
