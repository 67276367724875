import React, { Component } from 'react';
import T from "components/i18n";
// import Money from "components/money";
// import DOM from "react-dom-factories";
import { redirectTo } from 'tools/redirect-to'
import Notification from 'react-bulma-components/lib/components/notification';
import ScrollToTopOnMount from 'tools/scroll-top'


import { 
    Field, 
    Control, 
    Checkbox,
    Input, 
    Label, 
} from 'react-bulma-components/lib/components/form';

// import Columns from 'react-bulma-components/lib/components/columns';

import Heading from 'react-bulma-components/lib/components/heading';

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';

import SalesActions from 'modules/sales/sales-actions'

import ExpensesActions from 'modules/expenses/expenses-actions'
import ExpensesStore from 'modules/expenses/expenses-store'

import SelectComponent from 'tools/select-component';
import {confirmAlert} from "react-confirm-alert";

// import OpenModal from 'components/modal/modal';


class RefundModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          oid: props.oid,
          oiid: props.oiid || false,
          product_name: props.product_name,
          sale_amount: props.amount,
          saleAccount: {'value': props.fromAccount},
          fromAccount: null,
          amount_refund: 0,
          instock: true,
          close: false,
          message: null,
          step: 0,
          isHaveDps: props.dps
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeAccount = this.onChangeAccount.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.submit = this.submit.bind(this);
        this.renderStepOne = this.renderStepOne.bind(this);
        this.renderStepSecond = this.renderStepSecond.bind(this);

    }

    componentWillUnmount() {
      if (typeof(this.listenAdd) == "function") {
          this.listenAdd();
      }

      if (typeof(this.listenAddFailed) == "function") {
        this.listenAddFailed();
      }
    }

    componentDidMount() {
        this.listenAdd = SalesActions.refund.completed.listen((data) => {
          if (data['success']) {
            this.setState({step: 1});
          }
        });

        this.listenAddFailed = SalesActions.refund.failed.listen((res) => {
            const data = JSON.parse(res['response']);
            
            this.setState({
              "message": T(data['error']),
            });
        });
    }

    getMessage() {
        setTimeout(() => {
          this.setState({
            "message": null
          })
        }, 4000);
        return (
              <Notification color="danger">
                    <ScrollToTopOnMount />
                    {this.state.message}
                    <Button remove onClick={() => this.setState({message: null})} />
              </Notification>
        )
    }

    onChange(evt) {
        const value = evt.target.value,
            name = evt.target.name;

        this.setState({
          [name]: value
        });
    }

    onChangeCheckbox(evt) { 
        const value = evt.target.checked,
              name = evt.target.name

         this.setState({
          [name]: value
         })
    }


    onChangeAccount(selectedOption, actions) {
      if (!selectedOption) return;


      this.setState({
        [actions.name]: selectedOption
      });

      setTimeout(this.calcCommission, 10);
    }


    submit() {
        let data = {
            "oid": this.state.oid, 
            "aid": this.state.fromAccount.value, 
            "amount": this.state.amount_refund,
            "instock": this.state.instock ? 1 : 0
        };

        if (this.state.oiid) {
            data["oiid"] = this.state.oiid;
        }

        if(this.state.isHaveDps) {
           confirmAlert({
            title: T( 'check-fiscalized-check-return'),
            buttons: [
              {
                label: T( 'to-return'),
                onClick: () => {
                  SalesActions.refund(data);
                }
              },
              {
                label: T( 'no-refunds'),
                onClick: () => {
                  data.cancel_fiscal = true
                  SalesActions.refund(data);

                }
              },
              {
                label: T('close'),
                onClick: () => {}
              }
            ]
          });
        } else {
          SalesActions.refund(data);
        }

    }

    renderStepOne() {
      return (
          <Box>
              <Heading size={5}>
                  {T('refund')}: {this.state.product_name} <br />
              </Heading>
              <Box>
                <Field>
                    <Control>
                      <Label>{T('refund-sum')}</Label>
                      <div className="font-size80">{T('refund-sum-desk')}</div>
                      <Input  size="large" 
                            name="sale_amount"
                            disabled
                            type="number"
                            onChange={this.onChange} 
                            value={this.state.sale_amount}
                            />
                    </Control>
                </Field>
                <Field>
                    <Control>
                      <SelectComponent 
                          name="saleAccount"
                          readOnly
                          label="refund-account"
                          onChange={this.onChangeAccount} 
                          value={this.state.saleAccount} 
                          list={ExpensesStore.getAccountsList}
                          load={ExpensesActions.loadAccounts}
                          creatable={false}/>
                  </Control>
                </Field> 
              </Box>
              <Box>
                <Field>
                    <Control>
                      <Label>{T('amount-refund')}</Label>
                      <div className="font-size80">{T('amount-refund-desk')}</div>
                      <Input  size="large" 
                            name="amount_refund"
                            type="number"
                            onChange={this.onChange} 
                            value={this.state.amount_refund}
                            />
                    </Control>
                </Field>
                <Field>
                    <Control>
                      <SelectComponent 
                          name="fromAccount"
                          label="refund-debit-the-account"
                          onChange={this.onChangeAccount} 
                          value={this.state.fromAccount} 
                          list={ExpensesStore.getAccountsList}
                          load={ExpensesActions.loadAccounts}
                          creatable={false}/>
                  </Control>
                </Field> 

                <Field>
                        <Control>
                          <Checkbox 
                            name="instock" 
                            onChange={this.onChangeCheckbox} 
                            checked={this.state.instock} 
                          >{T('change-stock')}</Checkbox>
                        </Control>
                </Field> 
              </Box>
              <Button.Group>
                    <Button fullwidth size="large" rounded color="primary"
                      onClick={this.submit}>{T('refund-btn')}</Button>
              </Button.Group>
          </Box>
      );
    }

    renderStepSecond() {
      return (
        <Box>
          <Heading className="title">{T('refund-success')}</Heading>
          <Button.Group>
              <Button fullwidth size="large"  rounded color="primary"
                onClick={() => {this.props.onClose()}}
                >{T('close')}</Button>
            </Button.Group>
        </Box>
      );
    }

    render() {
        const stepNaviganor = [this.renderStepOne, this.renderStepSecond],
              currentStep = stepNaviganor[this.state.step];


        return (
              <div>
                {this.state.message ? this.getMessage() : ""}
                {currentStep()}
                {this.state.close ? redirectTo("/sales") : ""}
              </div>
        );

    }
       
}

export default RefundModal;