import React from 'react'
import BaseFilterLayout from "../../../components/base-filter-layout";
import SalesStore from "../../sales/sales-store";
import SalesActions from "../../sales/sales-actions";
import {getDatePeriod} from "../../../tools/date-range";
import UserActions from "../../../user/user-actions";

const date_start = getDatePeriod(30)

const EmployeesFilter = ({search, onChangeSearch}) => {
	const handleChangeDate = (value) => {
		const daysKey = Object.keys(value).reverse();
		daysKey.forEach((item) => {
			if(value[item]) {
				SalesActions.filter(item, value[item]);
			}
		});
	}

	const handleRefresh = () => {
		UserActions.getSubUsers()
		SalesActions.load()
	}

	return (
		<BaseFilterLayout
			refresh={{
				onChange: handleRefresh
			}}
			searchStringSetting={{
				onChange: onChangeSearch,
				defaultValue: search
			}}
			dateRangeSetting={{
				onChange: handleChangeDate,
				date_start: date_start,
				date_end: SalesStore.getFilter("date_end"),
			}}
		/>
	);
};

export default EmployeesFilter;