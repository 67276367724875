import React, { Component } from "react";
import T from "components/i18n";
import WarehouseStore from "modules/warehouse/warehouse-store";
import ProductsStore from "modules/products/products-store";
import Level from 'react-bulma-components/lib/components/level';
import OpenModalButton from "components/modal/open-modal-button";
import NumberBlock from "components/number-block";
import User from 'user/user-store';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Actions from './moving-actions'
import List from 'react-bulma-components/lib/components/list';
import Columns from 'react-bulma-components/lib/components/columns';
import Heading from 'react-bulma-components/lib/components/heading';
import Dropdown from 'react-bulma-components/lib/components/dropdown';
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";
import Button from "react-bulma-components/lib/components/button";
import AppStore from "../../app-store";
import PriceTagCountModal
  from "../../components/price-tag-count-modal/price-tag-count-modal";
import formatDate from "../../tools/format-date";
import UserStore from "user/user-store";

class MovingRow extends Component {
    getRegistrationButton() {
        const {data} = this.props;

        return (
            <Level.Item key={"regBut" + data.id}>
                <OpenModalButton size="small"  color="success" link={{
                              pathname: '/moving/edit',
                              state: {'step': 2, 'edit': true, 'data': data }
                            }} text={T('finish-moving-btn')} />
            </Level.Item>

        );
    }

    getEditButton() {
        const {data} = this.props;
        const warehouseFrom = WarehouseStore.getWarehouseById(data.from_marketplace_id);
        return warehouseFrom && (
            <Level.Item key={'edit-btn' + data.id}>
                 <Link to={{
                  pathname: '/moving/edit',
                  state: {'edit': true, disabledSelectMid: true, 'step': 0, 'data': data }
                }} title={T('btn-edit')}>
                        <FontAwesomeIcon icon="edit" size={this.props.isMobileSize ? '2x' : '1x'} />
                </Link>
              </Level.Item>
        );

    }

    getRevertButton() {
        const {data} = this.props;  

        return (
            <Level.Item key={'undo-btn' + data.id} className={this.props.isMobileSize ? 'margin-bottom-10' : ''}>
                 <Link to={{
                  pathname: '/moving/edit',
                  state: {'edit': false, 'step': 4, 'data': data }
                }} title={T('revert-moving')}>
                        <FontAwesomeIcon icon="undo" size={this.props.isMobileSize ? '2x' : '1x'} />
                </Link>
              </Level.Item>

        );
    }

    getDeleteButton() {
        const {data} = this.props;  

        return (
            <Level.Item key={"delBut" + data.id}>
                <Link to="#" onClick={() => this.deleteMoving(data['id'])} title={T('delete')}>
                        <FontAwesomeIcon icon="trash-alt" size={this.props.isMobileSize ? '2x' : '1x'} />
                </Link>
            </Level.Item>

        );
    }

    getPendingButton() {
        let response = [
            this.getRegistrationButton(),
            this.getEditButton(),
            this.getDeleteButton()
        ]

        return response;
    }

    getPendingMobileButton() {
      let response = []

      response.push(
        <>
        {this.getRegistrationButton()}
        <div className='write-off-mobile-actions'>
          {this.getPriceTagsButton()}
          {this.getEditButton()}
          {this.getDeleteButton()}
        </div>
      </>
      )

      return response
    }

    getPriceButton() {
        const {data} = this.props;

        return (
            <Level.Item key={'pricebt' + data.id}>
                <OpenModalButton size="small"  color="info" link={{
                              pathname: '/moving/edit',
                              state: {'step': 2, 'only_show': true, 'data': data }
                            }} text={T('see-product-btn')} />
            </Level.Item>

        );
    }


    getPrintButtonOne(sid) {
        const {data} = this.props;
        let pids = [];
        for (let key in data['items']) {
            const pr = data['items'][key];
            pids.push(ProductsStore.getIdent(pr['product_id'], data['to_marketplace_id']))
        } 

      return (<Dropdown up={this.props.isMobileSize}
          label={<FontAwesomeIcon icon="print" size={"1x"}  title={T('print')}/>}
        >
        <Dropdown.Item renderAs={Link} to={this.getPrintUrl(sid, "sale")} target="_blank" title={T('with-sale-price')} value="item">
            {T('with-sale-price')}  
        </Dropdown.Item> 
      </Dropdown>
      )
    }
    getPrintButton(sid) {
        const {data} = this.props;
        let pids = [];
        for (let key in data['items']) {
            const pr = data['items'][key];
            pids.push(ProductsStore.getIdent(pr['product_id'], data['to_marketplace_id']))
        } 
        if (!User.getPermissionEnable("incomeinformation")) {
            return this.getPrintButtonOne(data.id);
        }
      return (<Dropdown up={this.props.isMobileSize}
          label={<FontAwesomeIcon icon="print" size={"1x"}  title={T('print')}/>}
        >
            <Dropdown.Item renderAs={Link} to={this.getPrintUrl(data.id, "net")} target="_blank" title={T('with-netprice')} value="item">
                {T('with-netprice')}
            </Dropdown.Item> 
            <Dropdown.Item renderAs={Link} to={this.getPrintUrl(data.id, "sale")} target="_blank" title={T('with-sale-price')} value="item">
                {T('with-sale-price')}  
            </Dropdown.Item>  
          </Dropdown>
      )
    }

    getPriceTagsButton(withMargin) {
      return UserStore.getModuleEnable('pricetags') ? <Button
        className={`button-link-icon ${withMargin ? 'margin-bottom-10' : ''}`}
        style={this.props.isMobileSize ? {} : {marginLeft: 7}}
        onClick={() =>
          AppStore.openModal(<PriceTagCountModal movId={this.props.data.id} />)
        }
      >
        <FontAwesomeIcon
          icon="tags"
          size={this.props.isMobileSize ? '2x' : '1x'}
          title={T("print-tags")}
        />
      </Button> : null
    }

    deleteMoving(sid) {
        confirmAlert({
          title: T('confirm-delete'),
          message: '',
          buttons: [
            {
              label: T('delete'),
              onClick: () => Actions.delete({
                'mov_id': sid, 'type': 'moving'
              })
            },
            {
              label: T('no-delete'),
              onClick: () => {}
            }
          ]
        });
    }

    getPrintUrl(sid,_type) {
        return "/bprint/moving?mid="+sid+"&type="+_type+"&uid="+User.get("id")+"&token="+encodeURIComponent(User.getToken())
    }

    mobileRender() {
        const {data} = this.props,
              is_complete = (data['status'] === "completed");

      const fromWarehouseName = WarehouseStore.getWarehouseName(data['from_marketplace_id'], true)

      let className = " is-one-";
      className += this.props.num % 2 === 0 ? "even" : "odd";

        return (
            <tr key={data?.id} className={className}>
                <td colSpan="5">   
                  <Heading size={5} className="margin-top-10">ID: {data['id']}. {T('moving-from')} <span className='nowrap'>{formatDate(data['created_at'])}</span>
                    <b className="has-text-success nowrap">({T(data['status'])})</b>
                        </Heading>

                  <List>
                    {fromWarehouseName && <List.Item>
                      <b>{T("from-product-marketplace")}:</b> {T(WarehouseStore.getWarehouseName(data['from_marketplace_id']))}
                    </List.Item>}
                    <List.Item>
                      <b>{T("to-product-marketplace")}:</b> {T(WarehouseStore.getWarehouseName(data['to_marketplace_id']))}
                    </List.Item>
                    <List.Item>
                      <b>{T("products")}:</b> {data['items'].length} {T('items-e')}
                    </List.Item>
                  </List>
                  <Columns className="is-mobile product-mobile-block">
                              <Columns.Column>
                                     <NumberBlock top={'moving'} number={data['quantity'] || 0}  bottom="units" className="small-number-box" /> 
                              </Columns.Column>
                    {UserStore.getPermissionEnable('incomeinformation') && <Columns.Column>
                      <NumberBlock top="expected_goods_worth_short"
                                   number={data['amount'] || 0}
                                   bottom={User.getCurrency(data['currency'])}
                                   money={true} className="small-number-box"/>
                    </Columns.Column>}
                        </Columns>

                        <div>{data['comment']}</div>
                        <div className='display-flex-row' style={{flexWrap: 'wrap', justifyContent: 'space-between'}}>
                          {(data['status'] === "pending") && this.getPendingMobileButton()}
                          {is_complete ? (
                            <>
                              {this.getPriceButton()}
                              <div className='display-flex-row' style={{alignItems: 'center'}}>
                                {this.getPriceTagsButton(true)}
                                {this.getRevertButton()}
                              </div>
                            </>
                          ) : ""}
                        </div>
                </td>
            </tr>
        );
    }

    desktopRender() {
        const {data} = this.props;
        let comment = "";
        if (data['comment'] && data['comment'] !== "") {
            comment = data['comment']
        }

        const fromWarehouseName = WarehouseStore.getWarehouseName(data['from_marketplace_id'], true)
        let className = " is-one-";
        className += this.props.num % 2 === 0 ? "even" : "odd";

        return (
            <tr key={data?.id} className={`is-size-7 ${className}`}>
                <td align="left" width="30%">ID: {data['id']}. {T('moving-from')} {formatDate(data['created_at'])}<br />
                  {!!fromWarehouseName && <><b>{T("from-product-marketplace")}:</b> {T(WarehouseStore.getWarehouseName(data['from_marketplace_id']))} <br /></>}
                    <b>{T("to-product-marketplace")}:</b> {T(WarehouseStore.getWarehouseName(data['to_marketplace_id']))} <br />
                    {comment && <><b>{T("comment")}:</b> {comment}<br/></>}
                </td>
                <td>
                  <span className={data['status'] === 'completed' ? 'text-success' : 'text-warning'}><b>{T(data['status'])}</b></span>
                  <br />
                  <b>{T('moving')}:</b> {data['quantity']} {T('items-e')}<br />
                  <b>{T('products')}:</b> {data['items'].length} {T('items-e')}<br />
                </td>
                <td>
                  {User.getResponsebleName(data['responsible_user_id'])}
                </td>
                <td align="left" >
                    <Level className="margin-top-5">
                        <Level.Side align="left">
                          {(data['status'] === "pending") ? this.getPendingButton() : ""}      
                          {(data['status'] === "completed") ? [this.getPriceButton(), this.getRevertButton()] : ""} 
                          {this.getPrintButton()}
                          {this.getPriceTagsButton()}
                        </Level.Side>
                    </Level>  

                </td>
            </tr>
        );
    }   

    render() {
        return this.props.isMobileSize ? this.mobileRender() : this.desktopRender();
    }
}

export default withIsMobileSize(MovingRow);
