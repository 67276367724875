import React, { Component } from 'react';
import { connectToStores } from "tools/reflux-tools";
import ScrollToTopOnMount from 'tools/scroll-top'

import T from "components/i18n";
import { redirectTo } from 'tools/redirect-to'
import { 
    Field, 
    Control, 
    Checkbox, 
    Input, 
} from 'react-bulma-components/lib/components/form';

import Heading from 'react-bulma-components/lib/components/heading';

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';

import Actions from './transactions-actions'
import Store from './transactions-store'
import ExpensesStore from '../expenses/expenses-store'
import User from 'user/user-store';
import Notification from 'react-bulma-components/lib/components/notification';

import AppStore from 'app-store';
import SelectComponent from 'tools/select-component';

import SubmitButton from 'components/submit-button';
import InputWithLabelSelect
  from "../../components/input-with-label-select/input-with-label-select";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class AddAccountModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          name: {
                error: "",
                value: props.name || ""
          },
          beginBalance: {
                error: "",
                value: props.balance || 0
          },
          commissionIn: {
                error: "",
                value: props.commission_to || 0
          },
          commissionOut: {
                error: "",
                value: props.commission_from || 0
          },
          rounded: props.rounded || false,
          sales_commision: props.sales_commision ? true : false,
          typeAccount: {value: props.types || 0},
          currency_id: {value: props.currency_id || User.get("currency_id")},
          need_fiscal: props.need_fiscal === 0 ? false : true,
          not_allow_calc: props.not_allow_calc ? true : false,
          position: props.position ? {value:props.position} : 0,
          edit: props.id ? props.id : false,
          close: false,
          waiting: null,
          error: null,
          step: props.step || 0 
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.submit = this.submit.bind(this);

    }

    componentDidMount() {
      this.addaccount = Actions.addAccount.completed.listen((data) => {
            this.setState({'close': true, 'waiting': false});
            AppStore.closeModal();
        });

      this.addaccountfailed = Actions.addAccount.failed.listen((response) => {
        const data = JSON.parse(response['response']);
          let res = {'waiting': false}
          if (typeof(data['error']) !== "undefined") {
            res['error'] = data['error'];
          } 

          this.setState(res)
      });

    }

    componentWillUnmount() {
      if (typeof(this.addaccount) == "function") {
          this.addaccount();
      }

      if (typeof(this.addaccountfailed) == "function") {
          this.addaccountfailed();
      }

    }

    onChangeSelect(selectedOption, actionMeta) {
        this.setState({
          [actionMeta.name]: selectedOption
        })
    }

    onChangeCheckbox(evt) { 
        const value = evt.target.checked,
              name = evt.target.name
        this.setState({
          [name]: value
        });
    }

    submit() {
      if (this.state.name.value.length === "") {
        this.setState({
            'name': {
              'error': T('HTTP 400: Bad Request (no arguments)')
            }
          }
        );
        return false;
      }
      const _in = parseFloat(this.state.commissionIn.value),
            _out = parseFloat(this.state.commissionOut.value);

      if (!(_in >= 0 && _out >= 0)) {
          this.setState({
            'commissionIn': {
              'error': T('shoud > 0'),
              'value': 0
            },
            'commissionOut': {
              'error': T('shoud > 0'),
              'value': 0
            }
          });

          return;
      }

      this.setState({"waiting": true});

      Actions.addAccount({
          'name': this.state.name.value, 
          'sales_commision': (this.state.sales_commision ? 1 : 0), 
          'balance': this.state.beginBalance.value, 
          'typeAccount': this.state.typeAccount.value, 
          'currency_id': this.state.currency_id.value, 
          'need_fiscal': this.state.need_fiscal ? 1 : 0, 
          'not_allow_calc': this.state.not_allow_calc ? 1 : 0, 
          'position': this.state.position.value || 0, 
          'id': this.state.edit || 0,
          'com_in': _in, 
          'com_out': _out,
          'rounded': this.state.typeAccount.value === '0' ? Number(this.state.rounded) : 0
        }
      );

        
    }

    getMessage() {
      if (!this.state.error) return;

      return (
              <Notification color="danger">
                    <ScrollToTopOnMount />
                    {T(this.state.error)}
                    <Button remove onClick={() => this.setState({error: null})} />
              </Notification>
        )
    }

    onChange(evt) {
        const value = evt.target.value,
              name = evt.target.name;

        this.setState({
          [name]: {
            'value': value
          }
        });
    }


    render() {
        return (
          <div>
              {this.state.error ? this.getMessage() : ""}
              <Box>
                <Heading size={4}>{T(this.props.id ? "history-action-23" :'history-action-47')}</Heading>
                <Field>
                  <Control>
                    <Input  size="large"
                          name="name" type="text" 
                          placeholder={T('account-name')}
                          onChange={this.onChange} 
                          color={(this.state.name.error) ? "danger" : "primary"}
                          value={this.state.name.value}
                          />
                  </Control>
                </Field>

                {this.props.types !== 1 && <Field><InputWithLabelSelect
                  valueSelect={this.state.currency_id}
                  selectList={User.getCurrencyList()}
                  onChangeSelect={this.onChangeSelect}
                  selectName='currency_id'
                  value={this.state.beginBalance.value}
                  onChange={this.onChange}
                  label='account-begin-balance'
                  name='beginBalance'
                  placeholder='0'
                  color={(this.state.beginBalance.error) ? "danger" : "primary"}
                  type='number'
                  withNegative
                /></Field>}
                {this.props.types !== 1 && (
                  <>
                    <Field>
                          <SelectComponent
                              name="typeAccount"
                              onChange={this.onChangeSelect}
                              value={this.state.typeAccount}
                              list={() => ExpensesStore.getTypeActionsList().filter((t) => ['1', '4'].indexOf(t['id']) === -1 )}/>
                    </Field>
                  </>
                )}
                { User.getPRROList(false, true) ?
                <Field>
                      <Control>
                        <Checkbox 
                          name="need_fiscal" 
                          onChange={this.onChangeCheckbox} 
                          checked={this.state.need_fiscal} 
                        >{T('need_fiscal_sales')}</Checkbox>
                      </Control>
                </Field> : null}

                <Field>
                      <Control>
                        <Checkbox 
                          name="not_allow_calc" 
                          onChange={this.onChangeCheckbox} 
                          checked={this.state.not_allow_calc} 
                        >{T('not_allow_calc')}</Checkbox>
                      </Control>
                </Field>
                {this.state.typeAccount.value === '0' && <Field>
                  <Control>
                    <Checkbox
                      name="rounded"
                      onChange={this.onChangeCheckbox}
                      checked={!!this.state.rounded}
                    >{T(AppStore.getWlName() === "modeler" ? 'rounded_general' : 'rounded_NBU')}</Checkbox>
                  </Control>
                </Field>}
                  <Heading subtitle style={{fontWeight: 700, marginBottom: '5px'}}>{T('account-commission')}</Heading>
                <Field>
                  <InputWithLabelSelect
                      rightContent='%'
                      value={this.state.commissionIn.value}
                      onChange={this.onChange}
                      label='account-commission-in'
                      name='commissionIn'
                      placeholder='0'
                      color={(this.state.commissionIn.error) ? "danger" : "primary"}
                      type='number'
                      withNegative
                      max={100}
                  />
                </Field>
                <Field>
                    <Control>
                      <Checkbox
                        name="sales_commision"
                        onChange={this.onChangeCheckbox}
                        checked={this.state.sales_commision}
                      >{T('include_sales_commision')}</Checkbox>
                    </Control>
                </Field>
                <Field>
                  <InputWithLabelSelect
                    rightContent='%'
                    value={this.state.commissionOut.value}
                    onChange={this.onChange}
                    label='account-commission-out'
                    name='commissionOut'
                    placeholder='0'
                    color={(this.state.commissionOut.error) ? "danger" : "primary"}
                    type='number'
                    withNegative
                    max={100}
                  />
                </Field>
                <Field>
                  <InputWithLabelSelect
                    value={this.state.position.value}
                    onChange={this.onChange}
                    label='position'
                    name='position'
                    placeholder='1'
                    color={(this.state.position.error) ? "danger" : "primary"}
                    type='number'
                  />
                </Field>
                <Button.Group className='margin-top-20'>
                  <SubmitButton text={T(this.state.edit ? 'edit-account-btn-label' : 'add-account-btn-label')} waiting={this.state.waiting} submit={this.submit}/>
                </Button.Group>
                {this.state.close ? redirectTo("/transactions") : ""}
              </Box>
          </div>
        );
    }
}


export default connectToStores(AddAccountModal, { _: Store });
