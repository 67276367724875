import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

// import { Provider } from 'react-redux'
// import store from './store'

import * as serviceWorker from './serviceWorker';
import {
	IsMobileContext
} from "./components/is-mobile-context/is-mobile-context"; 
import {parseJSON} from "./tools/error-handler";
// import ErrorBoundary from "./error-boundary";
window.parent.postMessage('get-store', "*");
window.addEventListener("message", (event) => {
    if (event.data && typeof event.data === "string") {
        const [name, value] = event.data.split("::");

        if (name === 'store-data') {
            try {
                const data = parseJSON(value || '{}');
                
                Object.entries(data).forEach(([key, value]) => {
                    // Если value уже строка, не делаем stringify
                    const saveValue = typeof value === 'string' ? 
                        value : 
                        JSON.stringify(value);

                    window.localStorage.setItem(key, saveValue);
                });
            } catch (e) {
                console.error('Error parsing store data:', e);
            }
        }
    }
});

const rootElement = document.getElementById('root')

ReactDOM.render(
	<IsMobileContext>
		{/*<ErrorBoundary>*/}
		<App />
		{/*</ErrorBoundary>*/}
	</IsMobileContext>,
  rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
