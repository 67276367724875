import yajax from "yajax";
import React from "react";
import { createStore } from "../../tools/reflux-tools";
import Actions from './notification-actions';
import UserActions from 'user/user-actions';
import Cookies from 'universal-cookie';
import T from "../../components/i18n";
import UserStore from "../../user/user-store";
import {parseJSON} from "../../tools/error-handler";
import ScreenNotificationIcon from "./common/screen-notification-icon";
import NotificationModal from "./notification-dropdown/notification-modal";
import AppStore from "../../app-store";
import formatDate from "../../tools/format-date";

const NotificationStore = createStore({
	listenables: Actions,
    _prefix: "/bexpenses/notification",
    cookies: new Cookies(),
    state: {
        rows: null,
        front: null,
        isLoaded: false,
        messages: [],
        news: [],
        timeoutMessage: null,
        screenNotifications: {}
    },


    get(field) {
        return this.state[field];
    },

    getNotificationExpensesList() {
        const notification = this.getNotificationList(),
            result = notification.filter((row) => (row['is_expenses'] === 1));

        return result;
    },

    getNotificationList() {
        const notification = this.get("rows");

        if (notification === null) {
            Actions.load();
            return [];
        }

        return notification;
    },

    setNotificationMessage(data) {
      this.setState({
          message: data
      })
    },

    setLocalStorageNews(news) {
      const getLocalStorage = window.localStorage.getItem('news');
      if(getLocalStorage){
          const newsLocalStorage = parseJSON(getLocalStorage);
          news.forEach(item => {
              if(!newsLocalStorage[item.id]) {
                  newsLocalStorage[item.id] = {
                      is_read: 0,
                  };
              }
          })
          AppStore.saveLocalStorageAndMobile({key: 'news', value: JSON.stringify(newsLocalStorage)})

      } else {
          const data = news.reduce((acc, cur) => {
              acc[cur.id] = {
                  is_read: 0,
              }

              return acc;
          }, {})

          AppStore.saveLocalStorageAndMobile({key: 'news', value: JSON.stringify(data)})

      }
    },

    setReadNew(id, withoutLoad) {
        const getLocalStorage = window.localStorage.getItem('news');

        if(getLocalStorage){
            const parseLocalStorage = parseJSON(getLocalStorage);
            if(parseLocalStorage[id]) {
                parseLocalStorage[id].is_read = 1
            }
            AppStore.saveLocalStorageAndMobile({key: 'news', value: JSON.stringify(parseLocalStorage)})
            if(!withoutLoad) {
                Actions.loadMessages()
            }
        }
    },

    getReedNew(id) {
        const getLocalStorage = window.localStorage.getItem('news');
        const parseLocalStorage = parseJSON(getLocalStorage);

        return parseLocalStorage && !!parseLocalStorage[id]?.is_read;
    },

    onReadMessage(id) {
        yajax.post(
          '/bexpenses/messages', {id}
        ).then(Actions.readMessage.completed)
    },
    onReadMessageCompleted() {
      Actions.loadMessages()
    },

    onRemoveMessage(id) {
        const params = {
            method: "DELETE",
            url: "/bexpenses/messages",
            data: { id }
        };

      yajax(params).then(Actions.removeMessage.completed, Actions.removeMessage.failed);
    },
    onRemoveMessageCompleted() {
      Actions.loadMessages()
    },
    onRemoveMessageFailed() {
      console.log("remove failed");
    },

    renderNotificationModal(data){
        return <NotificationModal modal='notification-message' isRead={data.is_read} id={data.id} name={data.name} message={data.message} type={data.color} source={data.source}/>
    },

    onLoadMessages() {
      if(this.loadingNotificationMessage) return

        this.loadingNotificationMessage = true;
        clearTimeout(this.timeoutMessage)

        yajax.get(
          '/bexpenses/messages', {t: Date.now()}
        ).then(Actions.loadMessages.completed, Actions.loadMessages.failed);

    },

    onLoadMessagesCompleted(result) {
        this.loadingNotificationMessage = false
        clearTimeout(this.timeoutMessage)
        const data = result['data'];

        for (let i in data) {
            let mess = data[i],
              source = mess['source'];
            let _s =  source?.split("-");

            if(mess?.color === 'news' || mess?.color === 'success' || mess?.color === 'warning') {
                continue
            }
            if(mess?.is_read) {
                continue
            }

            if (_s && _s[0] === "integration") {
                let integr = UserStore.getIntegrationNameById(_s[1]);
                if (!integr['integration_id']) {
                    continue;
                }

                source = T('integration-'+integr['integration_id']);
            }

            Actions.addFrontNotification(mess['source']+"-"+mess['id'],formatDate(mess.created_at) + " " + T(source)+": "+T(mess['name']), mess['color'], null, mess);
        }
        this.timeoutMessage = setTimeout(Actions.loadMessages, 1000*60*3)

        const news = data.filter(item => item.color === 'news')

        this.setLocalStorageNews(news)

        const newData = data.map(item => {
            if(item.color === 'news') {
                item.is_read = this.getReedNew(item.id)
            }
            return item
        })
        newData.sort((a, b) => {
            if (a.is_read !== b.is_read) {
                return a.is_read ? 1 : -1;
            } else {
                return new Date(b.created_at) - new Date(a.created_at);
            }
        });


        if(this.init) {
            const prevMessage = this.state.messages
            const notificationScreenList =  newData.filter(({id}) => {
                return !prevMessage?.some(item => item.id === id)
            })

            notificationScreenList.forEach(item => {
                if(item.is_read) return
                const source = item.source
                if(source) {
                    const sourceSplit = source.split('-');
                    if(sourceSplit && sourceSplit[0] === "integration") {
                        let integration = UserStore.getIntegrationNameById(sourceSplit[1]);
                        this.addScreenNotification({name:`integration-${integration['integration_id']}`, message: item.name, modal:this.renderNotificationModal(item), icon: <ScreenNotificationIcon color={item.color}/>})
                    }
                } else {
                    this.addScreenNotification({name:item.name, message: item.message, icon: <ScreenNotificationIcon color={item.color}/>, modal:this.renderNotificationModal(item) })
                }

                return item
            })
        }

        this.init = true

        this.setState({
            'messages': newData,
        })

    },

    onLoadMessagesFailed() {
        this.loadingNotificationMessage = false
        clearTimeout(this.timeoutMessage)

        this.timeoutMessage = setTimeout(Actions.loadMessages, 1000*60*3)
    },

    onLoad() {
        if (this.state.isLoaded) return;

        this.setState({
            'isLoaded': true
        });

        yajax.get(this._prefix).then(Actions.load.completed, Actions.load.failed);
    },

    onLoadCompleted(data) {
        console.log("completed loading notification", data);

        this.setState({
            rows: data['data'],
            isLoaded: false
        })
    },

    onLoadFailed() {
        console.log("failed load notification");
        this.setState({
            'isLoaded': false
        });
    },

    removeAllFrontNotification() {
        this.cookies.set('notification', [], { path: '/', expires: new Date() });

        this.setState({
            front: []
        })
    },

    onRemoveFrontNotification(ident) {
        let front = { ...this.state.front};
        UserActions.readMessage(ident);
        if (ident in front) {
            let noti = this.cookies.get('notification')
            if (!noti) {
                noti = [];
            }
            noti.push(ident);

            let date = new Date();
            date.setDate(date.getDate() + 1);
            this.cookies.set('notification', noti, { path: '/', expires: date });
            delete front[ident];
        }

        if ( Object.keys(front).length === 0) {
            front = null;
        }



        this.setState({
            "front": front,
        })
    },

    onAddFrontNotification(ident, message, priority, data, messageData) {
        //priority = danger, warning, success

        let front = this.state.front;

        if (!front) {
            front = {};
        }

        if (typeof(priority) === "undefined") {
            priority ="success";
        }
        let noti = this.cookies.get('notification');
        if (noti && noti.indexOf(ident) > -1 && ['success', 'warning'].indexOf(priority) > -1) {
            return;
        }

        front[ident] = {'priority': priority, 'message': message, 'data': data, messageData: messageData?.message}

        this.setState({
            "front": front
        })

        console.log("success add front notification");
    },

    onAdd(types, amount, date, comment, is_expenses) {
        yajax.post(
            this._prefix,
            {
                "types": types,
                "amount": amount,
                "is_expenses": is_expenses,
                "date": date.toUTCString(),
                "comment": comment
            },
        ).then(Actions.add.completed, Actions.add.failed);
    },

    onAddCompleted(data) {
        console.log("completed add notification");
        Actions.load();
    },

    onAddFailed() {
        console.log("failed add notification");
    },

    addScreenNotification({name, message, url, modal, icon}) {
        const list = {...this.state.screenNotifications}

        const dateNow = Date.now()
        list[dateNow] = {name, message, id: dateNow, isVisible: true, url, modal, icon}

        this.setState({
            screenNotifications: list
        })
    },

    removeScreenNotification(id) {
        const list = {...this.state.screenNotifications};

        if(list[id]) {
            delete list[id]
        }

        this.setState({
            screenNotifications: list
        })
    },
});
    

export default NotificationStore;