import React, { useEffect, useState } from "react";
import { Box, Heading } from "react-bulma-components";
import T from "../../../../components/i18n";
import { Input, Label } from "react-bulma-components/lib/components/form";
import ErrorMessageControlHook from "../../../../components/error-message-block/error-message-control.hook";
import SalesActions from "../../sales-actions";
import { errorHandler } from "../../../../tools/error-handler";
import Money from "../../../../components/money";
import DOM from "react-dom-factories";
import SubmitButton from "../../../../components/submit-button";
import Loader from "react-loader-spinner";
import { printElem } from "../../../../tools/print";
import SalesStore from "../../sales-store";
import UserStore from "../../../../user/user-store";
import SelectComponent from "../../../../tools/select-component";

const PrepaymentFiscalization = ({ oid, amount, accountId, onClose }) => {
  const salesData = SalesStore.getSalesByOid(oid)
  const listPrro = UserStore.getPRROList(oid);

  const {
    handleAutoRemoveError,
    errorMessage,
    setErrorMessage,
    ErrorMessageBlock,
  } = ErrorMessageControlHook();
  const [isWaiting, setIsWaiting] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [value, setValue] = useState(`Передпалата за замовлення #${salesData?.order_number || ''}`);
  const [waitingPrint, setWaitingPrint] = useState(false);

  const [integrationInfo, setIntegrationInfo] = useState(
    listPrro &&
    Array.isArray(listPrro) && {
      value: listPrro[0].id,
      label: listPrro[0].name,
    }
  );

  const handleChange = (evt) => {
    const value = evt.target.value;
    setValue(value);
  };

  useEffect(() => {
    const setToDpsCompl = SalesActions.setToDps.completed.listen((data) => {
      setIsWaiting(false);
      if (data["type"] === "vchasno") {
        setIsCompleted(SalesStore.getConvertPdfToImage(SalesStore.getVchasnoKasaPrintUrl(data["check_id"], "pdf")));
      } else {
        setIsCompleted(SalesStore.getCheckboxPrintSlip(data["check_id"]));
      }

      const isAutoPrint = UserStore.getMoreSetting("is_auto_fiscal_print");
      if (isAutoPrint) {
        if(data["type"] === "vchasno") {
          handlePrintUrl(SalesStore.getConvertPdfToImage(SalesStore.getVchasnoKasaPrintUrl(data["check_id"], "pdf")))
        } else {
          handlePrintUrl(SalesStore.getCheckboxPrintSlip(data["check_id"]))
        }
      }
    });
    const setToDpsFail = SalesActions.setToDps.failed.listen((res) => {
      const message = errorHandler(res);
      handleAutoRemoveError(message);
      setIsWaiting(false);
    });

    return () => {
      setToDpsCompl();
      setToDpsFail();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    if (!!value) {
      setIsWaiting(true);
      const getInfoIntegration = UserStore.getIntegrationNameById(integrationInfo.value);
      const type = UserStore.getPRROName(getInfoIntegration.integration_id);
      SalesActions.setToDps(oid, value, type, getInfoIntegration.id);
    } else {
      handleAutoRemoveError("not all fields required");
    }
  };

  const handleChangeSelect = (select) => {
    setIntegrationInfo(select);
  };

  const handlePrintUrl = (url) => {
    printElem(url || isCompleted);
    setWaitingPrint(true)
    setTimeout(() => {setWaitingPrint(false)}, 4000)
  };

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <Box>
        <Heading>
          {T("send-to-dps-prepaid-heading")}{" "}
          <Money aid={accountId} amount={amount} wrapper={DOM.span} />
        </Heading>
        {!isWaiting && !isCompleted && (
          <>
            {!!listPrro.length && listPrro.length > 1 && (
              <SelectComponent
                label='cashier'
                value={integrationInfo}
                onChange={handleChangeSelect}
                list={() => listPrro}
              />
            )}
            <Label>{T("comment")}</Label>
            <Input value={value} onChange={handleChange} />
          </>
        )}
        {isWaiting && (
          <div className="display-flex-center">
            <Loader
              type="Circles"
              className="button_loader"
              color="#000000"
              height={30}
              width={30}
            />
          </div>
        )}
        <div
          className="display-flex-row margin-top-10 flex-wrap"
          style={{ justifyContent: "flex-end" }}
        >
          {isCompleted && (
            <SubmitButton
              fullwidth={false}
              size="medium"
              rounded
              waiting={waitingPrint}
              onClick={handlePrintUrl}
              color="info"
              text="print-fiscal-slip"
            />
          )}
          {!isCompleted && (
            <SubmitButton
              size="medium"
              rounded
              disabled={isWaiting}
              text="fiscalize-label"
              fullwidth={false}
              submit={handleSubmit}
              color="success"
            />
          )}
          <SubmitButton
            size="medium"
            rounded
            disabled={isWaiting}
            text="close"
            fullwidth={false}
            submit={onClose}
            color="light"
          />
        </div>
      </Box>
    </>
  );
};

export default PrepaymentFiscalization;
