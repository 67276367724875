import User from 'user/user-store';
import {isIOS} from "react-device-detect";

export const printPdf = (url) => {
  if (User.postMessage("print-url::"+url)) {
    return;
  }

  if(isIOS) {
    const printWindow = window.open("", "_blank");
    if (!printWindow) {
      return;
    }

    printWindow.document.write(`
                                    <html>
                                      <head>
                                        <style>
                                          @media print {
                                            @page { margin: 0; }
                                            body { text-align: center; margin: 0; }
                                          }
                                        </style>
                                      </head>
                                      <body>
                                        <img src="${url}" style="max-width: 100%;" onload="window.print(); setTimeout(() => window.close(), 500);">
                                      </body>
                                    </html>
                                  `);
    printWindow.document.close();

    return true
  }

  var iframe =  document.getElementById("iframeID");

  iframe.style.display = 'none';
  iframe.onload = function() {
      setTimeout(function() {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
   };

  iframe.src = url;
}

export const printElem = (url) => {
      if (User.postMessage("print-url::"+url)) {
        return;
      }

      if(isIOS) {
        const printWindow = window.open("", "_blank");
        if (!printWindow) {
          return;
        }

        printWindow.document.write(`
                                    <html>
                                      <head>
                                        <style>
                                          @media print {
                                            @page { margin: 0; }
                                            body { text-align: center; margin: 0; }
                                          }
                                        </style>
                                      </head>
                                      <body>
                                        <img src="${url}" style="max-width: 100%;" onload="window.print(); setTimeout(() => window.close(), 500);">
                                      </body>
                                    </html>
                                  `);
        printWindow.document.close();

        return true
      }

      const iframe = document.createElement('iframe');
      iframe.style.height = 'auto'; // Устанавливаем высоту печати
      iframe.style.visibility = 'hidden';
      iframe.style.width = '200px'; // Устанавливаем ширину печати
      iframe.setAttribute('srcdoc', '<html><head><style>@media print { @page { margin: 0; }</style></head><body print></body></html>');
      document.body.appendChild(iframe);
      
      iframe.addEventListener('load', function () {
        const img = document.createElement('img');
        img.src = url;
        img.style.maxWidth = '100%'; // Устанавливаем максимальную ширину изображения

        img.addEventListener('load', function() {
          iframe.style.height = img.height + 'px';
          const styleElement = document.createElement('style');
          styleElement.textContent = `@media print { @page {  size: 200px ${img.height+80}px; margin: 0;  } }`;

          // Создаем элемент <head> внутри <iframe>, если его нет
          const iframeHead = iframe.contentDocument.head || iframe.contentDocument.createElement('head');
            
          // Добавляем элемент <style> внутри <head> элемента <iframe>
          iframeHead.appendChild(styleElement);
          iframe.contentWindow.print();

        });

        const body = iframe.contentDocument.body;
        body.style.margin = '0'; // Устанавливаем отступы внутри iframe в ноль
        body.style.textAlign = 'center';
        body.appendChild(img);
      });

      iframe.contentWindow.addEventListener('afterprint', function () {
        iframe.parentNode.removeChild(iframe);
      });
      
      return true;
}

export default printPdf;