import React from 'react'
import AppBlock from "../../../templates/common/app-block/app-block";
import qrGoogle from "../img/qr-google.png";
import qrAppStore from "../img/qr-appstore.png";
import T from '../../../components/i18n'
import {Heading} from "react-bulma-components";

const appInfo = {
	android: {
		link: "https://play.google.com/store/apps/details?id=com.huprof.hugeprofit",
		qrCode: qrGoogle,
	},
	ios: {
		link: "https://apps.apple.com/app/hugeprofit-%D0%BE%D0%B1%D0%BB%D1%96%D0%BA-%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D0%B6%D1%96%D0%B2/id1544248357",
		qrCode: qrAppStore,
	},
};

const OldVersion = () => {
	return (
		<div className='display-flex-center' style={{flexDirection: 'column', height: '100vh', width: '100vw', padding: '10px'}}>
			<img src="/img/hp-black.png" alt="Huge Profit" width="200px" style={{marginBottom: '40px'}}/>
			<Heading>{T('old-app-message')}</Heading>
			<AppBlock android={appInfo.android} ios={appInfo.ios}/>
		</div>
	);
};

export default OldVersion;