import React, { Component } from "react";
import T from "components/i18n";
// import List from 'react-bulma-components/lib/components/list';

import Money from "components/money";
import User from 'user/user-store';
import formatDate from "../../tools/format-date";


class PaymentsRow extends Component {
    render() {
        const {data} = this.props;

        let response = [];

        response.push(
                <tr key={data['id']} >
                    <td style={{textAlign:"center"}}>{data['id']}</td>
                    <td style={{textAlign:"center"}}>{formatDate(data['created_at'])}</td>
                    <td style={{textAlign:"center"}}>{data['message']}</td>
                    <td style={{textAlign:"center"}}><Money amount={data['amount']} currency={User.getPaymentCurrency()}/></td>
                    <td style={{textAlign:"center"}}>{T(data['status'])}</td>
                </tr>

            )

        return response;
    }
}

export default PaymentsRow;
