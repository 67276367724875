import React, { Component } from 'react';
import T from 'components/i18n';

import Content from 'react-bulma-components/lib/components/content';
import Section from 'react-bulma-components/lib/components/section';
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import FooterBlock from '../../common/footer'
import AppStore from 'app-store'
import Level from 'react-bulma-components/lib/components/level';
import Button from 'react-bulma-components/lib/components/button';
import Image from 'react-bulma-components/lib/components/image';

import {Helmet} from "react-helmet";
import {createMobileAppLink} from "../../../tools/create-mobile-app-link";


class Contacts extends Component {
    render () {
        return (
            <>
        	<Section>
                <Helmet>
                    <title>{T('contact-title')}</title>
                    <meta name="description" content={T('contact-desc') + " " + T('phone-number-number')}/>
                </Helmet>
        				<Box>
               <p>{T('contact-text')}</p>
               
               <Button.Group position="centered">
                <a href="https://t.me/HugeProfitBot" onClick={(evt) => createMobileAppLink("https://t.me/HugeProfitBot", evt)} className="margin-side-10" target="_blank" rel="noopener noreferrer"><Image size={64}  src="/img/Telegram_logo.svg" border="0" /></a>
                <a href="viber://pa?chatURI=hugeprofit" onClick={(evt) => createMobileAppLink("viber://pa?chatURI=hugeprofit", evt)} target="_blank" rel="noopener noreferrer"><Image size={64}  src="/img/Viber-Icon.svg" border="0" /></a>
               </Button.Group>
               <hr />
               <Heading>{T('connect-to-socialnetwork')}</Heading>
                 <Level className="home-social-network is-mobile">
                    <Level.Item>
                        <a href="https://www.facebook.com/H-Profit-100740618389312" onClick={(evt) => createMobileAppLink("https://www.facebook.com/H-Profit-100740618389312", evt)}  target="_blank" rel="noopener noreferrer"><Image size={64}  src="/img/Facebook_F_icon.svg" border="0" /></a>
                    </Level.Item>
                    <Level.Item>
                        <a href="https://t.me/huge_profit" onClick={(evt) => createMobileAppLink("https://t.me/huge_profit", evt)} target="_blank" rel="noopener noreferrer"><Image size={64}  src="/img/Telegram_logo.svg" border="0" /></a>
                    </Level.Item>
                 </Level>
          </Box>
        	<Box>
        	<Content>
				<Heading>{T('contact_info')}</Heading>
				<dl>
				    <dt>{T('phone')}:</dt>
				    <dd className='margin-left-0'>{AppStore.getContactDataByLang('phone')}</dd>

				    <dt>{T('general_question')}:</dt>
				    <dd className='margin-left-0'><a href="mailto:support@h-profit.com">support@h-profit.com</a></dd>

				    <dt>{T('for_partner')}:</dt>
				    <dd className='margin-left-0'><a href="mailto:partners@h-profit.com">partners@h-profit.com</a></dd>

				    <dt>{T('company_address')}:</dt>
				    <dd className='margin-left-0'>{AppStore.getContactDataByLang('company')}</dd>

				    <dt>{T('for_post')}:</dt>
				    <dd className='margin-left-0'>{AppStore.getContactDataByLang('address')}</dd>           

            <dt>{T('for_address')}:</dt>
            <dd className='margin-left-0'>{AppStore.getContactDataByLang('address')}</dd>
				</dl>
				
			</Content>
			</Box>
			</Section>
            <FooterBlock />
            </>
			)}

}

export default Contacts;
