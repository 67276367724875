import React, {useRef} from "react";
import T from "../i18n";
import {Select} from "react-bulma-components/lib/components/form";
import s from './styles.module.scss'
import {checkValue} from "../number-input/number-input";

const InputWithLabelSelect = ({
  selectList,
  valueSelect,
  onChangeSelect,
  selectName,
  label,
  value,
	onChange,
  name,
  color,
  placeholder,
	type,
	withNegative,
	max,
	rightContent,
	disabledInput,
	className,
}) => {
	const refInput = useRef(null);

	const handleFocus = () => {
		refInput.current.focus();
	}

	const handleChangeSelect = ({target: {value, name}}) => {
		onChangeSelect({value}, {name});
	}

	const handleChange = (e) => {
		const { value } = e.target;
		if(type === 'number') {
			e.target.value = checkValue(value, withNegative, max)
		}
		onChange && onChange(e);
	};

  return (
    <div className={`${s.wrapper} ${color === 'danger' ? s.danger : ''} ${className ? className : ''}`} style={!(rightContent || selectList) ? {paddingRight: '10px'} : {}}>
			<div onClick={handleFocus} className={s.label}>{T(label)}</div>
      <input disabled={disabledInput} name={name} className={s.input} placeholder={placeholder} ref={refInput} value={value} onChange={handleChange}/>
			<div className={s.wrapper_right_content}>
        {selectList && selectList.length && (
          <Select name={selectName} value={valueSelect.value} size='small' onChange={handleChangeSelect} className={`${s.select} select-new`}>
            {selectList.map(({ id, name }) => (
              <option key={`select-value-${id}`} value={id}>{T(name)}</option>
            ))}
          </Select>
        )}
				{rightContent && <div className={s.right_content}>{rightContent}</div>}
			</div>
    </div>
  );
};

export default InputWithLabelSelect;
