import T from "../../../../../components/i18n";
import User from "../../../../../user/user-store";
import Money from "../../../../../components/money";
import DOM from "react-dom-factories";
import WarehouseStore from "../../../../warehouse/warehouse-store";
import ProductStore from "../../../products-store";
import React from "react";

const IMPORTANT_FIELD = ['category_id', 'asin', 'balance', 'name', 'profit',
	'instock', 'sku', 'net_price', 'price', 'amount', 'marketplace_id', 'new', 'old', 'total', 'comment',
	'integration_id', 'is_reserved', 'reserv', 'quantity', 'from_mid', 'to_mid', 'mov_id', 'source', 'old_price', 'new_price', 'mass_price'];
export const parseComment = (data) => {
	if (data[0] !== "{") {
		return [['', data]];
	}

	const comments = JSON.parse(data);
	let response = [];
	for (let i in comments) {
		const comment = comments[i];

		if (i === "products") {
			for (let j in comment) {
				let _comment = comment[j];
				response.push(T('new-instock', {'count': _comment['instock']}))
			}
		}

		if (IMPORTANT_FIELD.indexOf(i) > -1 && comment !== "") {
			let _string = comment;
			if (['amount', 'balance', 'price', 'net_price', 'profit','old_price', 'new_price'].indexOf(i) > -1) {
				if (["net_price", "profit"].indexOf(i) > -1 && !User.getPermissionEnable("incomeinformation")) continue;
				if (["price", "balance",'old_price', 'new_price'].indexOf(i) > -1 && !User.getPermissionEnable("salesprice")) continue;
				_string = (<Money amount={comment} wrapper={DOM.span} />)
			} else if (i === "responsible_user_id") {
				_string = User.getResponsebleName(_string);
			} else if (i === "marketplace_id") {
				_string = T(WarehouseStore.getWarehouseName(_string))
			} else if (['from_mid', 'to_mid'].indexOf(i) > -1) {
				_string = T(WarehouseStore.getWarehouseName(_string))
			} else if (i === "is_reserved") {
				_string = "Order ID: "+_string;
			} else if (i === "source") {
				_string = T(_string);
			} else if (i === "integration_id") {
				let integr = User.getIntegrationNameById(_string);
				_string = T('integration-'+integr['integration_id']);
			} else if(i === 'category_id') {
				_string = ProductStore.getCategoryName(_string)
			} else if(i === 'comment') {
				const regexProduction = /Production/g;
				const regexUndoProduction = /Undo production/g;

				_string = _string.replace(regexProduction, T('production'))
				_string = _string.replace(regexUndoProduction, T('undo-production'))

				_string = T(_string) || _string;
			} else if(i === 'mass_price') {
				const source = comments.source
				if(source === 'decrease_update_mass_price') {
					_string = `-${parseFloat(comment) * 100}%`
				} else if(source === 'increase_update_mass_price'){
					_string = `${parseFloat(comment) * 100}%`
				} else if(source === 'mass_change_price_increase' || source === 'mass_change_price_decrease') {
					_string = (<Money amount={comment} wrapper={DOM.span} />)
				} else if(source === 'mass_change_price_decrease') {
					_string = (<Money amount={comment} wrapper={DOM.span} />)
				} else if(source === 'mass_change_price_exact') {
					_string = (<Money amount={comment} wrapper={DOM.span} />)
					response.push(<div key={i}><b>{T('history-data-new_price')}:</b> {_string}</div>)
					continue;
				}
			}

			response.push([T('history-data-'+i), _string])
		}
	}

	return response;
}