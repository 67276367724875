export const currencies = {
	"1": {
		"country": "US",
		"code": "USD",
		"name": "United States Dollar",
		"symbol": "$",
		"timezone": "America/New_York"
	},
	"2": {
		"country": "UA",
		"code": "UAH",
		"name": "Ukrainian Hryvnia",
		"symbol": "₴",
		"timezone": "Europe/Kyiv"
	},
	"4": {
		"country": "AE",
		"code": "AED",
		"name": "United Arab Emirates Dirham",
		"symbol": "د.إ",
		"timezone": "Asia/Dubai"
	},
	"5": {
		"country": "EU",
		"code": "EUR",
		"name": "Euro",
		"symbol": "€",
		"timezone": "Europe/Brussels"
	},
	"6": {
		"country": "KZ",
		"code": "KZT",
		"name": "Kazakhstani Tenge",
		"symbol": "₸",
		"timezone": "Asia/Almaty"
	},
	"7": {
		"country": "GB",
		"code": "GBP",
		"name": "British Pound",
		"symbol": "£",
		"timezone": "Europe/London"
	},
	"8": {
		"country": "CN",
		"code": "CNY",
		"name": "Chinese Yuan",
		"symbol": "¥",
		"timezone": "Asia/Shanghai"
	},
	"9": {
		"country": "AZ",
		"code": "AZN",
		"name": "Azerbaijani Manat",
		"symbol": "₼",
		"timezone": "Asia/Baku"
	},
	"10": {
		"country": "CZ",
		"code": "CZK",
		"name": "Czech Koruna",
		"symbol": "Kč",
		"timezone": "Europe/Prague"
	},
	"11": {
		"country": "GE",
		"code": "GEL",
		"name": "Georgian Lari",
		"symbol": "₾",
		"timezone": "Asia/Tbilisi"
	},
	"12": {
		"country": "PL",
		"code": "PLN",
		"name": "Polish Złoty",
		"symbol": "zł",
		"timezone": "Europe/Warsaw"
	},
	"13": {
		"country": "TJ",
		"code": "TJS",
		"name": "Tajikistani Somoni",
		"symbol": "ЅМ",
		"timezone": "Asia/Dushanbe"
	},
	"14": {
		"country": "UZ",
		"code": "UZS",
		"name": "Uzbekistani Soʻm",
		"symbol": "so'm",
		"timezone": "Asia/Tashkent"
	},
	"15": {
		"country": "CH",
		"code": "CHF",
		"name": "Swiss Franc",
		"symbol": "CHF",
		"timezone": "Europe/Zurich"
	},
	"16": {
		"country": "UG",
		"code": "UGX",
		"name": "Ugandan Shilling",
		"symbol": "USh",
		"timezone": "Africa/Kampala"
	},
	"17": {
		"country": "IN",
		"code": "INR",
		"name": "Indian Rupee",
		"symbol": "₹",
		"timezone": "Asia/Kolkata"
	},
	"18": {
		"country": "JP",
		"code": "JPY",
		"name": "Japanese Yen",
		"symbol": "¥",
		"timezone": "Asia/Tokyo"
	},
	"19": {
		"country": "BR",
		"code": "BRL",
		"name": "Brazilian Real",
		"symbol": "R$",
		"timezone": "America/Sao_Paulo"
	},
	"20": {
		"country": "MX",
		"code": "MXN",
		"name": "Mexican Peso",
		"symbol": "$",
		"timezone": "America/Mexico_City"
	},
	"21": {
		"country": "KR",
		"code": "KRW",
		"name": "South Korean Won",
		"symbol": "₩",
		"timezone": "Asia/Seoul"
	},
	"22": {
		"country": "TH",
		"code": "THB",
		"name": "Thai Baht",
		"symbol": "฿",
		"timezone": "Asia/Bangkok"
	},
	"23": {
		"country": "TR",
		"code": "TRY",
		"name": "Turkish Lira",
		"symbol": "₺",
		"timezone": "Europe/Istanbul"
	},
	"24": {
		"country": "VN",
		"code": "VND",
		"name": "Vietnamese Dong",
		"symbol": "₫",
		"timezone": "Asia/Ho_Chi_Minh"
	},
	"25": {
		"country": "PH",
		"code": "PHP",
		"name": "Philippine Peso",
		"symbol": "₱",
		"timezone": "Asia/Manila"
	},
	"26": {
		"country": "ID",
		"code": "IDR",
		"name": "Indonesian Rupiah",
		"symbol": "Rp",
		"timezone": "Asia/Jakarta"
	},
	"27": {
		"country": "EG",
		"code": "EGP",
		"name": "Egyptian Pound",
		"symbol": "£",
		"timezone": "Africa/Cairo"
	},
	"28": {
		"country": "MY",
		"code": "MYR",
		"name": "Malaysian Ringgit",
		"symbol": "RM",
		"timezone": "Asia/Kuala_Lumpur"
	},
	"29": {
		"country": "NG",
		"code": "NGN",
		"name": "Nigerian Naira",
		"symbol": "₦",
		"timezone": "Africa/Lagos"
	},
	"30": {
		"country": "SA",
		"code": "SAR",
		"name": "Saudi Riyal",
		"symbol": "﷼",
		"timezone": "Asia/Riyadh"
	},
	"3": {
		"country": "RU",
		"code": "RUB",
		"name": "Russian Ruble",
		"symbol": "₽",
		"timezone": "Europe/Moscow"
	},
};