import React from "react";
import { Control } from "react-bulma-components/lib/components/form";
import {Button, Icon} from "react-bulma-components";
import s from './styles.module.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NumberInput from "../number-input/number-input";
import {useIsMobile} from "../is-mobile-context/is-mobile-context";

const InputWithCounter = ({minMinusDisabled,notDisabledMinus, value, onChange,sizeIcon, icon,  className, name, ...rest }) => {
  const {isMobileSize} = useIsMobile()
  const handleDecrement = (evt) => {
    evt.currentTarget.blur()
    const data = {
      target: {
        value: parseFloat(value || "0") - 1,
        name
      }
    }
    onChange(data, true)
  }

  const handleIncrement = (evt) => {
    evt.currentTarget.blur()
    const data = {
      target: {
        value: parseFloat(value || "0") + 1,
        name
      },
    }
    onChange(data, true)
  }

  let iconSize = isMobileSize ? '2x' : '1x'

  if(sizeIcon) {
    iconSize = sizeIcon
  }

  return (
    <div className='display-flex-row'>
      {!rest.disabled && <Button color='danger' className='button-icon'
               disabled={!notDisabledMinus && value <= (minMinusDisabled || 1)}
               onClick={handleDecrement}>
        <FontAwesomeIcon icon='minus-circle' size={iconSize}/>
      </Button>}
      <Control iconRight={!!icon} style={{flex: 1}}>
        <NumberInput withNegative={notDisabledMinus} {...rest} inputMode={isMobileSize ? 'decimal' : null} name={name} onChange={onChange} value={value} className={`${s.input} ${className}`}/>
        {icon && <Icon align="right">
          <span>
            {typeof icon === 'string' && icon.length > 3 ? `${icon.slice(0,3)}.` : icon}
          </span>
        </Icon>}
      </Control>
      {!rest.disabled && <Button color='success' className='button-icon'
               onClick={handleIncrement}>
        <FontAwesomeIcon icon='plus-circle' size={iconSize}/>
      </Button>}
    </div>
  );
};

export default InputWithCounter;
