import React, { useEffect } from "react";
import BaseFilterLayout from "../../../components/base-filter-layout";
import TransactionsStore from "../transactions-store";
import TransactionsFilter from "../transactions-filter";
import TransactionsActions from "../transactions-actions";
import ExpensesActions from "../../expenses/expenses-actions";
import { useLocation } from "react-router-dom";
import ExportDocumentDropdown from "./export-document-dropdown";

const DATA_SORT = [
  { label: "date", value: "created_at" },
  { label: "amount", value: "amount" },
  { label: "balance", value: "balance" },
  { label: "commission", value: "commission" },
];

const FilterPanel = () => {
  const location = useLocation();
  const handleChange = ({ name, value }) => {
    if (name) {
      TransactionsActions.filter(name, value);
    }
  };

  const handleChangeDate = (value) => {
    const daysKey = Object.keys(value);
    daysKey.forEach((item) => {
      if(value[item]) {
        TransactionsActions.filter(item, value[item]);
      }
    });
  };

  const handleRefresh = () => {
    TransactionsStore.clearFilter();
    TransactionsActions.load();
    ExpensesActions.load();
  };
  useEffect(() => {
    if (location.state?.expenses_category) {
      TransactionsActions.filter(
        "expenses_category",
        {value: location.state.expenses_category}
      );
    }
    if(location?.state?.search) {
      TransactionsActions.filter(
        "search",
        `${location?.state?.search}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const view = ["account", "expenses_category", "mid", 'amount-from',
    'amount-to']

  return (
    <BaseFilterLayout
      searchStringSetting={{
        onChange: handleChange,
        defaultValue: TransactionsStore.getFilter("search"),
      }}
      sortSetting={{
        data: DATA_SORT,
        defaultActive: TransactionsStore.getFilter("sort"),
        onChange: (value) => TransactionsStore.onFilter("sort", value),
      }}
      refresh={{
        onChange: handleRefresh,
        isLoading: TransactionsStore.get("isLoaded"),
      }}
      dateRangeSetting={{
        onChange: handleChangeDate,
        date_start: TransactionsStore.getFilter("date_start"),
        date_end: TransactionsStore.getFilter("date_end"),
      }}
      hiddenFilter={<TransactionsFilter />}
      filterView={{
        list: TransactionsStore.get('_filter'),
        onDelete: TransactionsStore.filterClearField,
        view
      }}
      additionalSetting={<ExportDocumentDropdown/>}
    />
  );
};

export default FilterPanel;
