import React, { Component } from "react";
import formatDate from "../../tools/format-date";
// import T from "components/i18n";
// import List from 'react-bulma-components/lib/components/list';

class CardRow extends Component {
    render() {
        const {data} = this.props;

        let response = [];

        response.push(
                <tr key={data['id']} >
                    <td style={{textAlign:"center"}}>{formatDate(data['created_at'])}</td>
                    <td style={{textAlign:"center"}}>{data['account_num']}</td>
                </tr>

            )

        return response;
    }
}

export default CardRow;
