import React, {useEffect, useState} from "react";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Checkbox,
  Control, Field,
  Input,
  Label,
} from "react-bulma-components/lib/components/form";
import T from "../../../components/i18n";
import Icon from "react-bulma-components/lib/components/icon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bulma-components";
import SelectComponent from "../../../tools/select-component";
import SalesActions from "../sales-actions";
import SalesStore from "../sales-store";
import {errorHandler} from "../../../tools/error-handler";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const selectWeightListForDocument = [
  {name: 0.1, id: 0.1},
  {name: 0.5, id: 0.5},
  {name: 1, id: 1},
]

const DimensionsBlock = ({index, values, onChangeInput,type, onDelete, onCopy, onDeletePack, onError, isPostmate}) => {
  const {isMobileSize} = useIsMobile()
	const sizeClass = isMobileSize ? "small" : null;
  const [isPack, setIsPack] = useState(false)
  const [waitingPack, setWaitingPackLoading] = useState(false)
  const [packingList, setPackingList] = useState([])

  const handleChangeSelect = ({value}) => {
    const evt = {
      target: {
        value,
        name: 'weight'
      }
    }
    onChangeInput && onChangeInput(evt, index);
  }

  const handleChangeCheckbox = ({target: {checked}}) => {
    setIsPack(checked);
    if(checked) {
      SalesActions.loadPackListNP({
        Width: values.width || null,
        Height: values.height || null,
        Length: values.length || null
      })
      setWaitingPackLoading(true)

      const evt = {
        target: {
          value: '',
          name: 'pack'
        }
      }

      onChangeInput && onChangeInput(evt, index);
    } else {
      onDeletePack()
    }
  }
  useEffect(() => {
    const loadPackingList = SalesActions.loadPackListNP.completed.listen((data) => {
      setPackingList(SalesStore.createPackingListNPForSelect(data.Filtered))
      setWaitingPackLoading(false)
    })
    const loadPackingListFailed = SalesActions.loadPackListNP.failed.listen((res) => {
      setWaitingPackLoading(false)
      setIsPack(false)
      const error = errorHandler(res)
      onError && onError(error)
    })
    return () => {
      loadPackingList()
      loadPackingListFailed()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePack = ({value}) => {
    const evt = {
      target: {
        value,
        name: 'pack'
      }
    }

    onChangeInput && onChangeInput(evt, index);
  }

  return (
    <>
			<div className='display-flex-row-gap'>
				<Label>{T("dimensions")} #{index + 1}</Label>
        {type !== 'Documents' && <div className='display-flex-row'>
          {!isPostmate && <Button className='button-link-icon'
                   onClick={() => onCopy && onCopy(values)}>
            <FontAwesomeIcon icon='copy'/>
          </Button>}
          {index !== 0 && <Button className='button-link-icon'
                                  onClick={() => onDelete && onDelete(index)}>
            <FontAwesomeIcon icon='trash-alt'/>
          </Button>}
        </div>}
			</div>
      <Columns className="is-mobile">
        <Columns.Column>
          <Control iconRight>
            {type === 'Documents' ? (
              <SelectComponent
                onChange={handleChangeSelect}
                value={{value: values.weight, label: values.weight}}
                placeholder='weight'
                list={() => selectWeightListForDocument}
              />
              ) :
            <Input
              size={sizeClass}
              inputMode={isMobileSize ? 'decimal' : null}
              key={"weight"}
              name={"weight"}
              placeholder={T("weight")}
              type="number"
              min="0.1"
              onChange={(evt) => onChangeInput && onChangeInput(evt, index)}
              value={values.weight}
            />}
            <Icon align="right" style={type === "Documents" ? {right: 38} : {}}>
              <span>{T("kg")}</span>
            </Icon>
          </Control>
        </Columns.Column>
      </Columns>
      {type !== 'Documents' && <Columns className="is-mobile">
        <Columns.Column>
          <Control iconRight>
            <Input
              size={sizeClass}
              inputMode={isMobileSize ? 'decimal' : null}
              name={"length"}
              placeholder={T("length")}
              type="number"
              min="1"
              onChange={(evt) => onChangeInput && onChangeInput(evt, index)}
              value={values.length}
            />
            <Icon align="right">
              <span>{T("cm-short")}</span>
            </Icon>
          </Control>
        </Columns.Column>
        <Columns.Column>
          <Control iconRight>
            <Input
              size={sizeClass}
              name={"width"}
              inputMode={isMobileSize ? 'decimal' : null}
              placeholder={T("width")}
              type="number"
              min="1"
              onChange={(evt) => onChangeInput && onChangeInput(evt, index)}
              value={values.width}
            />
            <Icon align="right">
              <span>{T("cm-short")}</span>
            </Icon>
          </Control>
        </Columns.Column>
        <Columns.Column>
          <Control iconRight>
            <Input
              size={sizeClass}
              name={"height"}
              inputMode={isMobileSize ? 'decimal' : null}
              placeholder={T("heigth")}
              type="number"
              min="1"
              onChange={(evt) => onChangeInput && onChangeInput(evt, index)}
              value={values.height}
            />
            <Icon align="right">
              <span>{T("cm-short")}</span>
            </Icon>
          </Control>
        </Columns.Column>
      </Columns>}
      {type !== 'Documents' && !isPostmate && <div className="margin-bottom-10">
        <Field style={{fontSize: "18px"}}>
          <Checkbox name='packing' checked={isPack}
                    onChange={handleChangeCheckbox}>
            Пакування
          </Checkbox>
        </Field>
        {isPack && !waitingPack && !!packingList.length && <SelectComponent
          name='pack'
          list={() => packingList}
          onChange={handleChangePack}
        />}
      </div>}
    </>
  );
};

export default DimensionsBlock;
