import React from "react";
import Dropdown from "react-bulma-components/lib/components/dropdown";
import { AVAILABLE_LANGS } from "../../configs/wl-name/api";
import {useHistory, useLocation} from "react-router-dom";
import UserStore from "../../user/user-store";
import {Button} from "react-bulma-components";
import AppStore from "../../app-store";
import {loadMessages} from "../../configs/i18n";

const SelectLang = () => {
  const keysLang = Object.keys(AVAILABLE_LANGS);

  let lang_text = (
    <span className="uppercase bold-text">{UserStore.getLang()}</span>
  );

  const location = useLocation()
  const history = useHistory()

  const handleChangeLang = (lang) => {
    AppStore.saveLocalStorageAndMobile({key: 'lang', value: lang})
    document.documentElement.lang = lang === 'ua' ? 'uk' : lang;
    const pathname = location.pathname
    history.push(`${pathname.replace(/(ua|en|ru)/, lang)}${location.hash}`)
    loadMessages(lang).then(() => {
      UserStore._onSetLang(lang);
    })
  }

  return (
    <Dropdown hoverable right label={lang_text} className='disabled-background'>
      {keysLang.map((lang) => (
        <Dropdown.Item key={lang} value={lang}>
          <Button
            className='button-link-without-style'
            style={{width:'100%', justifyContent:'left'}}
            onClick={() => handleChangeLang(lang)}
          >
            {AVAILABLE_LANGS[lang]["full"]}
          </Button>
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};

export default SelectLang;
