import React from 'react'
import BaseFilterLayout from "../../components/base-filter-layout";
import InventoryActions from "./inventory-actions";
import InventoryStore from "./inventory-store";
import InventoryFilters from "./inventory-filters";

const FilterPanel = () => {
	const handleRefresh = () => {
		InventoryActions.load(true)
	}

	const handleChangeDate = (value) => {
		const daysKey = Object.keys(value).reverse()
		daysKey.forEach(item => {
			if(value[item]) {
				InventoryActions.filter(item, value[item]);
			}
		})
	}

	const handleChangeSearch = ({name, value}) => {
		if(name) {
			InventoryActions.filter(name, value);
		}
	}

	return (
		<BaseFilterLayout
			searchStringSetting={{
				onChange: handleChangeSearch,
				defaultValue: InventoryStore.getFilter("search")
			}}
			refresh={{
				onChange: handleRefresh
			}}
			dateRangeSetting={{
				onChange: handleChangeDate,
				date_start: InventoryStore.getFilter("date_start"),
				date_end: InventoryStore.getFilter("date_end"),
			}}
			hiddenFilter={<InventoryFilters/>}
		/>
	);
};

export default FilterPanel;