import React, { Component } from "react";
// import { GOOGLE_API } from "configs/wl-name/api";
import yajax from "yajax";
import trackEvent from "tools/track-events";
import { Switch, Route, Redirect } from "react-router-dom";
import modals from "components/routes/account-modals";
import UserStore from "user/user-store";
import UserActions from "user/user-actions";
// import GoogleOneTapLogin from "react-google-one-tap-login";
import AppStore from "app-store";
import routes from "../../components/routes/account-routes";
import { saveInitialTrafficSource } from "modules/registration/trafic-source";

import { SVGSource } from "./icons";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      showOneTap: false,
    };

    this.onCheckAuth = this.onCheckAuth.bind(this);
  }

  onCheckAuth(evt) {
    // console.log("check auto: ", evt);
    if (evt.data && typeof evt.data === "string") {
      let res = evt.data.split(":");
      if (res[0] === "profit-security") {
        let login_str = res[1].split(";");
        AppStore.setIsMobileApp(true);
        UserActions.login(login_str[0], login_str[1]);
      }
    }
  }

  componentWillUnmount() {
    if (window.removeEventListener) {
      window.removeEventListener("message", this.onCheckAuth);
    } else {
      window.detachEvent("onmessage", this.onCheckAuth);
    }

    if (typeof this.loginComplete == "function") {
      this.loginComplete();
    }
  }

  componentDidMount() {
    UserActions.checkIp(false);

    const params = new URLSearchParams(window.location.search);
    if (params) {
      const partner_id = params.get("partner_id");
      if (partner_id) {
        const decoded_partner_id = atob(partner_id);
        window.localStorage.setItem("partner_id", decoded_partner_id);
      }
    }

    saveInitialTrafficSource();

    if (window.addEventListener) {
      window.addEventListener("message", this.onCheckAuth);
    } else {
      window.attachEvent("onmessage", this.onCheckAuth);
    }

    this.loginComplete = UserActions.login.completed.listen((data) => {
      if (data["success"]) {
        this.setState({
          login: true,
        });
      }
    });

    setTimeout(() => {
      this.setState({ showOneTap: true });
    }, 30000);
  }

  responseGoogle(response) {
    const data = {
      email: response["email"],
      name: response["name"],
      userID: response["nbf"],
      accessToken: response["aud"],
      graphDomain: "google.com",
    };

    const params = {
      method: "POST",
      url: "/userprofile/social",
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
      },
      data: {
        lang: UserStore.getLang(),
        currency_id: UserStore.get("currency_id"),
        social_network: JSON.stringify(data),
      },
    };

    const partner_id = window.localStorage.getItem("partner_id");
    if (partner_id) {
      params.data["partner_id"] = partner_id;
    }

    return yajax(params).then((data) => {
      if (data.success) {
        if (data.first) {
          trackEvent("users", "registration", "google", "1");
          UserStore.setFirstReg(true);
        } else {
          UserStore.setFirstReg(false);
        }
        UserActions.changeToken(data.token, true);
        window.localStorage.removeItem("partner_id");
      }
    });
  }

  // returnGoogleOneTap() {
  //   return (
  //     <GoogleOneTapLogin
  //       onError={(error) => console.log(error)}
  //       onSuccess={this.responseGoogle}
  //       googleAccountConfigs={{ client_id: GOOGLE_API, auto_select: true }}
  //     />
  //   );
  // }

  renderMain() {
    const isGuest = UserStore.isGuest();
    return (
      <>
        <Switch>
          {isGuest && AppStore.isMobileApp() && <Route exact path="/">
            <Redirect to={AppStore.getLink("login")} />
          </Route>}
          {isGuest && AppStore.isMobileApp() && <Route exact path="/:lang/">
            <Redirect to={AppStore.getLink("login")} />
          </Route>}
          {routes.map((route, index) => {
            if (!route.auth && isGuest) {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              );
            } else {
              return <Route key={index} exact path={route.path}>
                         <Redirect to='/' />
                     </Route>
            }
          })}
          {modals.map((route, index) => {
            if (!route.auth || isGuest) {
              return (
                <Route
                  key={index}
                  path={route.path}
                  children={<route.main />}
                />
              );
            } else {
              return "";
            }
          })}
        </Switch>
        {/*{this.returnGoogleOneTap()}*/}
      </>
    );
  }

  renderMobile() {
    return (
      <>
          {this.renderMain()}
      </>
    );
  }

  renderDesktop() {
    const handleGetDoc = (lang) => {
      return {
        ua: "/blog/dokumentacziya/",
        en: "/blog/en/documentation/",
        ru: "/blog/ru/dokumentacziya-2/",
      }[lang];
    };

    const list = [
      {
        link: `/${UserStore.getLang()}/${AppStore.getLink("#services")}`,
        label: "service-link",
      },
      {
        link: `/${UserStore.getLang()}/${AppStore.getLink("#price")}`,
        label: "price-link",
      },
      {
        link: `/${UserStore.getLang()}/${AppStore.getLink("#review")}`,
        label: "review-link",
      },
      {
        link: handleGetDoc(UserStore.getLang()),
        label: "documentation",
      },
      {
        link: AppStore.getLink("public_contacts"),
        label: "contact-link",
      },
    ];

    if(AppStore.isEnableForWhiteLable("partners") && AppStore.getDocsLink("partners")) {
      list.push({
        link: AppStore.getDocsLink("partners"),
        label: 'partners'
      })
    }

    return (
      <>
        {this.renderMain()}
      </>
    );
  }
  render() {
    const isMobileApp = AppStore.isMobileApp();

    return (
      <>
        {isMobileApp ? this.renderMobile() : this.renderDesktop()}
        <SVGSource />
      </>
    );
  }
}

export default DefaultLayout;
