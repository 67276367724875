import React from "react";
import s from "./styles.module.scss";
import {createMobileAppLink} from "../../../tools/create-mobile-app-link";

const AppBlock = ({android, ios}) => {
  return (
    <section className={s.qr_wrapper}>
      <a
        className={s.app_link}
        href={android.link}
        onClick={(event) => createMobileAppLink(android.link, event)}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          className={s.qr_img}
          src={android.qrCode}
          alt="Qr code Google play app"
        />
        <img
          className={s.app_img}
          src="/img/playmarket_ua@2x.webp"
          border="0"
          alt="HugeProfit in GooglePlay"
        />
      </a>
      <a
        className={s.app_link}
        href={ios.link}
        onClick={(event) => createMobileAppLink(ios.link, event)}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          className={s.qr_img}
          src={ios.qrCode}
          alt="Qr code AppStore app"
        />
        <img
          className={s.app_img}
          src="/img/appstore@2x.webp"
          alt="HugeProfit in AppStore"
          border="0"
        />
      </a>
    </section>
  );
};

export default AppBlock;
