import {Help} from "react-bulma-components/lib/components/form";
import T from "../../../components/i18n";
import React from "react";

const HelpError = ({ error }) => {
	return <Help color="danger" style={{
		textAlign: "right",
		width: '100%',
		marginTop: '-8px',
	}}>{T(error)}</Help>;
};

export default HelpError;