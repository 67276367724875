import React, {useEffect, useState} from 'react';
import DefaultLayout from './whitelables/wl-name/default-layout.js'
import AccountLayout from './account-layout.js'
import UserStore from 'user/user-store';
import { useLocation} from "react-router-dom";
import UserActions from "./user/user-actions";
import AppStore from "./app-store";
import {isAndroid, isIOS} from "react-device-detect";
import OldVersion from "./whitelables/hugeprofit/common/old-version";

const BaseLayout = () => {
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search);
	const [isOldApp, setIsOldApp] = useState(false)

	const token = searchParams.get("token");

	useEffect(() => {
		if(token) {
			UserActions.changeToken(token);
		}

		window.addEventListener("touchstart", (event) => {
			const targetA = event.target.closest('a');
			if (targetA && AppStore.isMobileApp()) {
				event.preventDefault();
			}
		});

		AppStore.checkVersion(true);

		window.addEventListener("message", (event) => {
			if (event.data && typeof event.data === "string") {
				const res = event.data.split(":");
				const name = res[0]
				const value = res[1]
				if(name === 'app-version') {
					const currentVersions = AppStore.get('currentVersion')
					if(!!Object.keys(currentVersions).length) {
						let isOldVersion = false
						let sendNotification = false
						const splitVersionIos = currentVersions?.ios.split(".")
						const splitCurrentVersionAndroid = currentVersions?.android.split(".")

						const newVersion = value.split(".")

						if(isIOS) {
							isOldVersion = splitVersionIos[0] !== newVersion[0]
							sendNotification = splitVersionIos[1] !== newVersion[1]
							sendNotification = splitVersionIos[2] !== newVersion[2]
						} else if(isAndroid) {
							isOldVersion = splitCurrentVersionAndroid[0] !== newVersion[0]
							sendNotification = splitCurrentVersionAndroid[1] !== newVersion[1]
							sendNotification = splitCurrentVersionAndroid[2] !== newVersion[2]
						}

						if(isOldVersion) {
							setIsOldApp(true)

							setTimeout(() => {
								UserStore.postMessage('hide-barcode');
							}, 2000)
						}

						if(sendNotification) {}

					} else {
						AppStore.checkVersion(true);
					}
				}
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	if(isOldApp) {
		return <OldVersion />
	}

	return UserStore.isGuest() ?  <DefaultLayout /> : <AccountLayout />
}


export default BaseLayout;