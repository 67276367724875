import React, { Component } from 'react';
import T from "components/i18n";
import Box from 'react-bulma-components/lib/components/box';
import ProductsActions from '../products/products-actions'
import ProductsStore from '../products/products-store'
import Button from 'react-bulma-components/lib/components/button';
import List from 'react-bulma-components/lib/components/list';
import Columns from 'react-bulma-components/lib/components/columns';
import Heading from 'react-bulma-components/lib/components/heading';
import User from 'user/user-store';
import Icon from 'react-bulma-components/lib/components/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";

import { 
    Field, 
    Control, 
    Input, 
} from 'react-bulma-components/lib/components/form';
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";
import SubmitButton from "../../components/submit-button";

class ChangeNetPriceModal extends Component {
   constructor(props) {
        super(props);

        const {price, pid, mid} = this.props; 


        const product_data = ProductsStore.getProductByMid(pid,mid);

        this.isDisableNet = product_data['current_sid'] ? true : false;

        this.state = {
          lines: [{'name': T('shipments-price-item'), 'amount': price, 'type_id': this.isDisableNet ? 2 : 1}],
          total: price,
          button: T('change')
        };

        this.listenLoadCompleted = null;

        this.onChange = this.onChange.bind(this);
        this.addLine = this.addLine.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
         const {pid, mid, onClose } = this.props;

         ProductsActions.loadNetPrices(pid, mid);

         this.listenLoadCompleted = ProductsActions.loadNetPrices.completed.listen((data) => {
            if (data['success']) {
                if (data['data'].length > 0) {
                    let total = 0;
                    for (let j in data['data']) {
                       let pr = data['data'][j]['amount']
                       total += parseFloat(pr);
                    }

                    data['data'].sort(function(a, b) {
                      var x = a['type_id']; var y = b['type_id'];
                      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                    });

                    this.setState({
                      'lines': data['data'],
                      'total': total
                    })  
                } 
            }
         });

      this.listenSetCompleted = ProductsActions.setGroupNetPricesPrice.completed.listen((data) => {
          ProductsActions.loadByPid([pid])
          if (typeof(onClose) == "function") {
              setTimeout(() => {
                onClose()
              }, 100);
          }
        });
      this.listenSetCompleted = ProductsActions.setGroupNetPricesPrice.failed.listen((res) => {
        this.setState({
          waiting: false
        })
      })
    }

    componentWillUnmount() {
        if (typeof(this.listenLoadCompleted) === "function") {
              this.listenLoadCompleted();
        }
        if (typeof(this.listenSetCompleted) === "function") {
              this.listenSetCompleted();
        }
    }

    submit() {        
        const {mid, pid} = this.props;

        if (!this.state.lines || this.state.lines.length === 0) {
          return false;
        }

        let data = {
          'mid': mid,
          'pid': pid,
          'lines': JSON.stringify(this.state.lines)
        }

        console.log("start send netprice ", data);
        this.setState({
          waiting: true,
        })
        ProductsActions.setGroupNetPricesPrice(data);
    }

    onChange(evt, i) {
        const value = evt.target.value,
              name = evt.target.name;

        // let button = T('change');

        let lines = this.state.lines;
        lines[i][name] = value;

        let total = 0;
        for (let j in lines) {
           let pr = lines[j]['amount']
           total += isNaN(parseFloat(pr)) ? 0 : parseFloat(pr);
        }
        this.setState({
          'lines': lines,
          'total': total
        });
    }

    getList() {
      let response = [];


      for (let i in this.state.lines) {
        response.push(this.getLine(i));
      }

      return response;
    }

    isRawPrice(type_id) {
      return [1,2].indexOf(type_id) > -1 
    }

    deleteLine(i) {
      let lines = this.state.lines;

      if (!this.isRawPrice(lines[i]['type_id'])) {
        delete lines[i];

        this.setState({
          'lines': lines
        });
      }
    }

    addLine() {
      let lines = this.state.lines;

      lines.push({
        'name': '',
        'type_id': 3,
        'amount': 0
      })

      this.setState({
        'lines': lines
      });
    }

    isNetDisabled(i) {
      return this.isDisableNet &&  this.isRawPrice(this.state.lines[i]['type_id'])
    }

    getLine(i) {
      return (
          <List.Item key={i}>
            <Columns className={this.props.isMobileSize ? "is-mobile" : ""}>
              <Columns.Column size={this.props.isMobileSize ? "half" : "three-fifths"}>
                  <Field>
                      <Control >
                        <Input  size="large" 
                              name="name"
                              type="text"
                              placeholder={T('expenses-name')}
                              onChange={(evt) => {this.onChange(evt, i)}} 
                              value={this.state.lines[i]['name']}
                              />
                      </Control>
                </Field>
              </Columns.Column>
              <Columns.Column size={this.props.isMobileSize ? "" : "one-quarter"}>
                 <Field>
                      <Control iconRight>
                        <Input  size="large" 
                              name="amount"
                              type="number"
                              placeholder={T("netprice-price")}
                              readOnly={this.isNetDisabled(i) ? true : false}
                              disabled={this.isNetDisabled(i) ? true : false}
                              onChange={(evt) => {this.onChange(evt, i)}} 
                              value={this.state.lines[i]['amount']}
                              />
                          <Icon align="right">
                            <span className="small-text">{User.getCurrency()}</span>
                          </Icon>
                      </Control>
                </Field>
              </Columns.Column>
              <Columns.Column size={this.props.isMobileSize ? "one-quarter" : ""} align="center" className="margin-top-10">
                  {!this.isRawPrice(this.state.lines[i]['type_id']) ? 
                  <Link to="#" onClick={() => this.deleteLine(i)} title={T('delete')}>
                      <FontAwesomeIcon icon="trash-alt" size="2x" />
                  </Link>
                  : null}
              </Columns.Column>
            </Columns>
          </List.Item>
        );

    }


    render () {
        const {onClose} = this.props
        return (
              <Box>
                <Heading size={this.props.isMobileSize ? 5 : 3}>
                    {T('calculate-netcost-full')} <br />
                </Heading>

                <List>
                    {this.getList()}
                </List>
                <div align="right">
                  <Button size="small"  rounded color="primary"
                      onClick={this.addLine}
                      >{T('add-expenses-btn')}</Button>
                </div>
                <Box>
                    <div className="big-text">
                      {T('total')}: {this.state.total} {User.getCurrency()} <br />
                    </div>
                </Box>
                  <Button.Group>
                    <SubmitButton size="medium"  rounded color="primary"
                                  submit={this.submit} text={this.state.button} waiting={this.state.waiting} fullwidth={false}/>
                    <Button size="medium" rounded color="light"
                      onClick={onClose}
                      >{T('close')}</Button>
                  </Button.Group>
              </Box>
        );
    }

}

export default withIsMobileSize(ChangeNetPriceModal);