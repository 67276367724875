import React from 'react'
import {Box, Button, List} from "react-bulma-components";
import UserStore from "../../../../user/user-store";
import T from "../../../../components/i18n";
import AppStore from "../../../../app-store";
import EditUserProfile from "./edit-user-profile";

const UserProfile = () => {
	return (
		<Box>
			<div align="left" className="display-flex-column">
				<span style={{fontSize: '1.2rem', marginBottom: 5}}>
					<b>ID</b>: {UserStore.get("id")}
				</span>
				<List>
					<List.Item>
						<b>{T('your-email')}</b>: {UserStore.get("email") || "-"}
					</List.Item>
					<List.Item>
						<b>{T('phone')}</b>: {UserStore.get("phone") || "-"}
					</List.Item>
					<List.Item>
						<b>{T('first-name')}</b>: {UserStore.get("first_name") || "-"}
					</List.Item>
					<List.Item>
						<b>{T('last-name')}</b>: {UserStore.get("last_name") || "-"}
					</List.Item>
				</List>
				<div align="right">
					<Button color='success' onClick={() => AppStore.openModal(<EditUserProfile/>)}>{T('btn-edit')}</Button>
				</div>
			</div>
		</Box>
	);
};

export default UserProfile;